// components
import Header from "../components/Header";
import Footer from "../components/Footer";
import {useContext, useEffect} from "react";
import Cookies from "js-cookie";
import {UserContext} from "../contexts/UserContext";


function CancellationPolicy() {
    return (
        <div className="info-page">
            <Header/>
            <div id="page_header">
                <h1>Cancellation Policy</h1>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="container-wrap">
                            <div id="page_content">
                                <p>This cancellation policy outlines the procedures and terms related
                                    to order cancellations for products purchased from JaduPc ("we",
                                    "our", or "us"). By placing an order on our website, you agree to
                                    comply with the cancellation procedures described in this policy.</p>
                                <ol>
                                    <li>
                                       <b>Order Cancellation</b> You may request to cancel your order
                                        under the following conditions:
                                        <ul>
                                            <li className="indent">
                                                Before Shipment: If you wish to cancel your order before it has been
                                                shipped, you can do so by contacting our customer service team at
                                                hello@jadupc.com. We recommend reaching out as soon as possible to
                                                avoid processing and shipping.
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        <b>Cancellation Process:</b> To request an order cancellation,
                                        follow these steps:
                                        <ul>
                                            <li className="indent">
                                                Contact our customer service team at hello@jadupc.com.
                                            </li>
                                            <li className="indent">
                                                Provide your order number and the reason for cancellation.
                                            </li>
                                            <li className="indent">
                                                Our customer service team will review your request and provide
                                                further instructions.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <b>Refund for Canceled Orders:</b> If your order is successfully
                                        canceled before it has been shipped, a full refund will be issued to
                                        the original payment method used for the purchase. The refund will
                                        be processed within 3 to 7 business days from the date of
                                        cancellation approval.
                                    </li>
                                    <li>
                                       <b>Exceptions:</b> Please note that we cannot guarantee the
                                        cancellation of an order if it has already been processed for
                                        shipment. In such cases, you may need to initiate a return or follow
                                        the procedures outlined in our Refund Policy.
                                    </li>
                                    <li>
                                        If you have any questions or concerns about order cancellations or
                                        need assistance with the cancellation process, please contact our
                                        customer service team at hello@jadupc.com.
                                    </li>
                                    <li>
                                        By placing an order with JaduPc, you acknowledge that you have read,
                                        understood, and agreed to the terms and procedures outlined in this
                                        cancellation policy.
                                    </li>
                                </ol>
                                <p>Last Updated: 13th August, 2023</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
}

export default CancellationPolicy;
