import React from 'react'
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

//context variable
import { UserContext } from "../contexts/UserContext";
import { useContext, useEffect, useState } from "react";

/**
 * Protected tour, check if user authenticated, if not then route to login with error
 *
 * @param redirectto
 * @param children
 * @returns {JSX.Element|*}
 * @constructor
 */
function ProtectedRoute({ redirectto , children }) {
  const { checkLogin , setName, setToken , userIsLoggedIn, setUserIsLoggedIn } = useContext(UserContext);
  

  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    /*if (Cookies.get("token")) {
      setName(Cookies.get("name"))
      setToken(Cookies.get("token"))
      setUserIsLoggedIn(true)
    }else if(!checkLogin()){
      setRedirect(true);      
    }*/

    //console.log('from protected component checking if user is logged in ');

    if(!checkLogin()){
      //console.log('user is not logged in  so redirect');
      //console.log(userIsLoggedIn);

      setRedirect(true);
    }
  }, []);

  //console.log('should redirect = ', redirect);

  //user is not authenticated
  if(redirect){
    if (redirectto) {
      return <Navigate to={`/login?error=protected&redirectto=${redirectto}`} />
    }
    else{
      return <Navigate to={`/login?error=protected`} />
    }
  }


  return children;
}
export default ProtectedRoute;

