import Header from "../components/Header";
import Footer from "../components/Footer";
import {useContext, useEffect} from "react";
import Cookies from "js-cookie";
import {UserContext} from "../contexts/UserContext";


function PrivacyPolicy() {
    return (
        <div className="info-page">
            <Header/>
            <div id="page_header">
                <h1>Privacy Policy</h1>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="container-wrap">
                            <div id="page_content">
                                <p> This privacy policy outlines how JaduPc ("we", "our", or "us")
                                    collects, uses, discloses, and safeguards your personal information.
                                    By accessing our website, using our services, or purchasing our
                                    products, you consent to the practices described in this policy.</p>
                                <ol>

                                    <li>
                                        <b>Information Collection:</b> We may collect the following types
                                        of information:
                                        <ul>
                                            <li className="indent">
                                                <b>Personal Information:</b> When you place an order, create an
                                                account, we may collect your name, email address, shipping address,
                                                billing information, and contact details.
                                            </li>
                                            <li className="indent">
                                                <b>Device Information:</b>We collect information about the device
                                                you use to access our website, including your IP address, browser
                                                type, operating system, and referring URLs.
                                            </li>
                                            <li className="indent">
                                                <b>Usage Data:</b> We gather information about your interactions
                                                with our website, such as pages visited, products viewed, and
                                                actions taken.
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        <b>Use of Information:</b> We use the collected information for
                                        the following purposes:
                                        <ul>
                                            <li className="indent">
                                                <b>Order Processing:</b> To process your orders, fulfill your
                                                requests, and provide customer support.
                                            </li>
                                            <li className="indent">
                                                <b>Personalization:</b> To enhance your experience by tailoring our
                                                products and services to your preferences.
                                            </li>

                                            <li className="indent">
                                                <b>Communication:</b> To send order confirmations, updates,
                                                newsletters, and promotional offers.
                                            </li>
                                            <li className="indent">
                                                <b>Analytics:</b> To analyze website usage, trends, and
                                                effectiveness of our marketing campaigns.
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        3) <b> Information Sharing:</b> We do not sell, trade, or rent your
                                        personal information to third parties. However, we may share your
                                        information with:
                                        <ul>
                                            <li className="indent">
                                                <b>Service Providers:</b> We may share information with third-party
                                                service providers who assist us in website operations, payment
                                                processing, shipping, and customer support.
                                            </li>
                                            <li className="indent">
                                                <b>Legal Compliance:</b> We may disclose information if required by
                                                law, court order, or government request.
                                            </li>
                                            <li className="indent">
                                                <b>Business Transfers:</b> In the event of a merger, acquisition, or
                                                sale of assets, your information may be transferred to the new
                                                entity.
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        4) <b>Cookies and Tracking Technologies:</b> We use cookies, web
                                        beacons, and similar technologies to collect information about your
                                        browsing behavior and improve your online experience. You can
                                        control cookies through your browser settings.
                                    </li>
                                    <li>
                                        5) <b>Data Security:</b> We implement security measures to protect
                                        your personal information from unauthorized access, disclosure,
                                        alteration, and destruction. However, no method of transmission over
                                        the internet is entirely secure, and we cannot guarantee absolute
                                        security.
                                    </li>
                                    <li>
                                        6) <b>Changes to this Policy:</b> We may update this Privacy Policy
                                        from time to time. We will notify you of any significant changes by
                                        posting a prominent notice on our website.
                                    </li>
                                    <li>
                                        7) <b>Contact Us:</b> If you have any questions, concerns, or
                                        requests regarding this privacy policy or your personal information,
                                        please contact us at hello@jadupc.com.
                                    </li>
                                    <li>
                                        By using our website or providing your information to us, you
                                        acknowledge that you have read, understood, and agreed to the
                                        practices described in this privacy policy.
                                    </li>

                                </ol>
                                <p>Last Updated: 13th August, 2023</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
}

export default PrivacyPolicy;
