// variant specs
// styles must be added from the callee's css
const SpecsItem = ({featureName, featureDetail}) => {
    return (
        <div className="specs-item">
            <div className="featureName">{featureName}</div>
            <div className="featureDetail">{featureDetail}</div>
        </div>
    );
};

export default SpecsItem;
