// dependencies
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// pages
import Home from "./pages/Home";
import OrderStarter from "./pages/OrderStarter";
import OrderStarterPrivate from "./pages/OrderStarterPrivate";
import OrderPro from "./pages/OrderPro";
import Signup from "./pages/Signup";
import ExpressInterestForm from "./pages/ExpressInterestForm";
import Login from "./pages/Login";
//import StarterDetail from "./pages/StarterDetail";
import TermsConditions from "./pages/TermsConditions";
import RefundPolicy from "./pages/RefundPolicy";
import DeliveryPolicy from "./pages/DeliveryPolicy";
import CancellationPolicy from "./pages/CancellationPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Dashboard from "./pages/Dashboard";
import Success from "./pages/payment/Success";
import Failed from "./pages/payment/Failed";
import OrderDetail from "./pages/OrderDetail";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import ProtectedRoute from "./components/ProtectedRoute";

import UserContextProvider from "./contexts/UserContext";



function App() {
  return (
    <UserContextProvider>
    <div className="app">

        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />;
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/express-interest-form" element={<ExpressInterestForm />} />
            <Route path="/terms" element={<TermsConditions />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/cancellation" element={<CancellationPolicy />} />
            <Route path="/refund" element={<RefundPolicy />} />
            <Route path="/delivery" element={<DeliveryPolicy />} />
            <Route
              path="/order-starter"
              element={
                <ProtectedRoute redirectto="order-starter">
                  <OrderStarter />
                </ProtectedRoute>
              }
            />

              <Route
                  path="/order-starter-private"
                  element={
                      <ProtectedRoute redirectto="order-starter-private">
                          <OrderStarterPrivate />
                      </ProtectedRoute>
                  }
              />

            <Route
              path="/order-pro"
              element={
                <ProtectedRoute redirectto="order-pro">
                  <OrderPro />
                </ProtectedRoute>
              }
            />

            <Route
              path="/dashboard"
              element={
                <ProtectedRoute redirectto="dashboard">
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route path="/success" element={<Success />} />
            <Route path="/fail" element={<Failed />}></Route>
            <Route
              path="/order/:orderID"
              element={
                <ProtectedRoute>
                  <OrderDetail />
                </ProtectedRoute>
              }
            ></Route>
            {/*<Route path="/starter" element={<StarterDetail />} />*/}
          </Routes>
        </BrowserRouter>

    </div>
    </UserContextProvider>
  );
}

export default App;
