// dependencies
import {useState, useEffect, forwardRef} from "react";
import {Link, Navigate, useSearchParams} from "react-router-dom";
import axios from "axios";

//notification components
import AWN from "awesome-notifications"


import Cookies from "js-cookie"
import Header from "../components/Header";
import Footer from "../components/Footer";


import {createTheme, ThemeProvider} from "@mui/material/styles";
import TextField from "@mui/material/TextField";



const theme = createTheme({
    components: {
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontFamily: "Figtree Regular",
                },
            },
        },
        MuiTextField       : {
            styleOverrides: {
                label: {
                    fontFamily: "Figtree Regular",
                },
                input: {
                    fontFamily: "Figtree Regular",
                },
            },
        },
    },
});

function ForgetPassword(props) {

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonText, setButtonText] = useState('Submit');
    const [validForm, setValidForm] = useState(true);

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    if (!isLoggedIn && Cookies.get('token')) {
        setIsLoggedIn(true);
    } else if (isLoggedIn && !Cookies.get('token')) {
        setIsLoggedIn(false);
    }

    let [urlSearchParams] = useSearchParams(); // get access to the URL query string parameters

    // create state variables to hold username and password
    const [response, setResponse] = useState({}); // the API will return an object with a JWT token, if the user logs in successfully

    // what to do when the user clicks the submit buton on the form
    const handleSubmit = async (e) => {
        // prevent the HTML form from actually submitting... we use React's javascript code instead
        e.preventDefault();

        //client side validation
        if(e.target.email.value == ''){
            new AWN().alert('Email address is empty', {durations: {success: 0}})
            setValidForm(false);
            return;
        }

        //email validation
        const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
        const isValidEmail = emailRegex.test(e.target.email.value);
        if(!isValidEmail){
            new AWN().alert('Invalid Email address', {durations: {success: 0}})
            setValidForm(false);
            return;
        }
        setValidForm(true);
        //check if form is valid
        if(validForm){
            //valid email address
            try {
                setButtonDisabled(true);
                setButtonText('Processing ...');

                //create an object with the data we want to send to the server
                const requestData = {
                    email: e.target.email.value,
                };
                // send a POST request with the data to the server api to authenticate
                const response    = await axios.post(
                    process.env.REACT_APP_API_DOMAIN + `api/v1/password/forget`,
                    requestData
                );
                // store the response data in the data state variable
                setResponse(response.data);

                if(response.data.success == true){
                    new AWN().success('We have sent you an email, please check your inbox and spam folder, from email click the Reset Password Button and reset your password from the reset form window', {durations: {success: 0}})
                    setButtonDisabled(false);
                    setButtonText('Submit');
                }else if(response.data.success == false){
                    new AWN().alert(response.data.status.email[0], {durations: {success: 0}})
                    setButtonDisabled(false);
                    setButtonText('Submit');
                }
            } catch (err) {
                if (err.response && err.response.data && err.response.data.error) {
                    const errorObj = err.response.data.error;

                    if (errorObj.email) {
                        // Assuming there's only one error message for each field
                        new AWN().alert(errorObj.email[0], {durations: {success: 0}})
                        setButtonDisabled(false);
                        setButtonText('Submit');
                    }

                    if (errorObj.password) {
                        new AWN().alert(errorObj.password[0], {durations: {success: 0}})
                        setButtonDisabled(false);
                        setButtonText('Submit');
                    }
                }
            }//end catch
        }


    };


    return (
        <div className="signup">
           {/* <NotificationsSystem />*/}
            <Header/>
            <div className="container">
                <div className="row">
                    <div className="offset-md-3 col-md-6">
                        {/*<Blob />*/}
                        <ThemeProvider theme={theme}>
                            <form onSubmit={handleSubmit}>
                                <div className="signup-container">
                                    <div className="signup-title">Forget Password</div>
                                    <div className="signup-fields">
                                        <TextField
                                            required
                                            id="filled-basic-email"
                                            type="text"
                                            label="Email"
                                            name="email"
                                            variant="filled"
                                            InputProps={{
                                                sx              : {borderRadius: "10px"},
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />
                                    </div>
                                    <div className="signup-button">
                                        <button type="submit" className="proceed-button" disabled={buttonDisabled}>
                                            { buttonText }
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </ThemeProvider>
                    </div>
                </div>
            </div>


            <Footer/>
        </div>
    );

}

export default ForgetPassword;
