// dependencies
import {useEffect, useState , useContext} from "react";
import Cookies from "js-cookie";
import {Link} from "react-router-dom";

// ui
import logo from "../assets/images/logo.png";
//import "../css/Header.css";
import MenuIcon from "@mui/icons-material/Menu";

//context variable
import { UserContext } from "../contexts/UserContext";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";


function Header() {
    const { checkLogin } = useContext(UserContext);

    useEffect(() => {
        checkLogin();
    }, []);

    // const [isLoggedIn, setIsLoggedIn] = useState(false);
    // if (!isLoggedIn && Cookies.get('token')) {
    //     setIsLoggedIn(true);
    // } else if (isLoggedIn && !Cookies.get('token')) {
    //     setIsLoggedIn(false);
    // }
    const { userIsLoggedIn } = useContext(UserContext);

    const [isNavExpanded, setIsNavExpanded] = useState(false);
    return (
        <div className="header">
            <nav className="navigation">
                <Link to="/" className="brand-name">
                    <img src={logo} alt="Jadupc Logo" />
                </Link>
                <button
                    className={
                        isNavExpanded ? "hamburger hamburger--squeeze js-hamburger is-active" : "hamburger"
                    }
                    onClick={() => {
                        setIsNavExpanded(!isNavExpanded);
                    }} type="button">
                   {/* <MenuIcon
                        style={{
                            color   : "white",
                            fontSize: "1rem",
                        }}
                    />*/}
                    <div className="hamburger-box">
                        <div className="hamburger-inner"></div>
                    </div>
                </button>
                <div
                    className="desktop-menu">
                    <ul>
                        {/*<li><a href="tel:+8809606990077"><PhoneInTalkIcon/> 09606990077</a></li>*/}
                        <li><a href="tel:+8801331538779"><PhoneInTalkIcon/> +8801331538779</a></li>
                        <li>
                            {userIsLoggedIn ? (
                                <Link to="/dashboard">Dashboard</Link>
                            ) : (
                                <Link to="/login">Login</Link>
                            )}
                        </li>
                    </ul>
                </div>
            </nav>
            <div
                className={
                    isNavExpanded ? "mobile-menu expanded" : "mobile-menu"
                }>
                <ul>
                    <li>
                        {userIsLoggedIn ? (
                            <Link to="/dashboard">Dashboard</Link>
                        ) : (
                            <Link to="/login">Login</Link>
                        )}
                    </li>
                    {/*<li><a href="tel:+8809606990077">Support(+8809606990077)</a></li>*/}
                    <li><a href="tel:+8801331538779">Support(+8801331538779)</a></li>
                </ul>
            </div>
        </div>
    );
}

export default Header;
