// dependencies
import {useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie"

//notification components
import AWN from "awesome-notifications"

// components
//import Blob from "../components/Blob";
import Header from "../components/Header";
import Footer from "../components/Footer";


import {createTheme, ThemeProvider} from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// import custom phone number input component
import CustomPhoneNumberInput from "../components/CustomPhoneNumberInput";

// import react phone number input things
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const theme = createTheme({
    components: {
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontFamily: "Figtree Regular",
                },
            },
        },
        MuiTextField       : {
            styleOverrides: {
                label: {
                    fontFamily: "Figtree Regular",
                },
                input: {
                    fontFamily: "Figtree Regular",
                },
            },
        },
    },
});

function Signup(props) {

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [signupButtonText, setSignupButtonText] = useState('Signup');

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    if (!isLoggedIn && Cookies.get('token')) {
        setIsLoggedIn(true);
    } else if (isLoggedIn && !Cookies.get('token')) {
        setIsLoggedIn(false);
    }

    const navigate = useNavigate();

    const [termsChecked, setTermsChecked] = useState(false);

    const [phoneNumberValue, setPhoneNumberValue] = useState();

    // what to do when the user clicks the submit buton on the form
    const handleSubmit = async (e) => {
        // prevent the HTML form from actually submitting... we use React's javascript code instead
        e.preventDefault();

        if (!termsChecked) {
            new AWN().alert("Please agree to the terms and conditions and privacy policy", {durations: {success: 0}});
            return;
        }
        if(e.target.password.value !== e.target.confirm_password.value){
            new AWN().alert("Password did't Match", {durations: {success: 0}});
            return;
        }
        try {
            setButtonDisabled(true);
            setSignupButtonText('Processing ...');
            // create an object with the data we want to send to the server
            const requestData = {
                name                 : e.target.name.value,
                email                : e.target.email.value,
                mobile_number        : phoneNumberValue,
                password             : e.target.password.value,
                password_confirmation: e.target.password.value,
            };

            // send a POST request with the data to the server api to authenticate
            await axios.post(
                process.env.REACT_APP_API_DOMAIN + `api/v1/marketplace/signup`,
                requestData
            );

            navigate("/login?msg=register_success");
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error) {
                const errorObj = err.response.data.error;

                if (errorObj.email) {
                    // Assuming there's only one error message for each field
                    new AWN().alert(errorObj.email[0], {durations: {success: 0}});
                    setButtonDisabled(false);
                    setSignupButtonText('Signup');
                }

                if (errorObj.password) {
                    // Assuming there's only one error message for each field
                    new AWN().alert(errorObj.password[0], {durations: {success: 0}});
                    setButtonDisabled(false);
                    setSignupButtonText('Signup');
                }

                if (errorObj.mobile_number) {
                    // Assuming there's only one error message for each field
                    new AWN().alert(errorObj.mobile_number[0], {durations: {success: 0}});
                    setButtonDisabled(false);
                    setSignupButtonText('Signup');
                }

            }
        }
    };

    return (
        <div className="signup">
            <Header/>
            <div className="container">
                <div className="row">
                    <div className="offset-md-3 col-md-6">
                        {/*<Blob />*/}
                        <ThemeProvider theme={theme}>
                            <form onSubmit={handleSubmit}>
                                <div className="signup-container">
                                    <div className="signup-title">Create an account</div>
                                    <div className="signup-fields">
                                        <TextField
                                            required
                                            id="filled-basic-name"
                                            label="Name"
                                            name="name"
                                            variant="filled"
                                            InputProps={{
                                                sx              : {borderRadius: "10px"},
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />
                                        <TextField
                                            required
                                            id="filled-basic-email"
                                            type="email"
                                            label="Email"
                                            name="email"
                                            variant="filled"
                                            InputProps={{
                                                sx              : {borderRadius: "10px"},
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />

                                        {/*<TextField*/}
                                        {/*    required*/}
                                        {/*    id="filled-basic-mobile_number"*/}
                                        {/*    label="Mobile Number"*/}
                                        {/*    name="mobile_number"*/}
                                        {/*    variant="filled"*/}
                                        {/*    type="tel"*/}
                                        {/*    InputProps={{*/}
                                        {/*        sx              : {borderRadius: "10px"},*/}
                                        {/*        disableUnderline: true,*/}
                                        {/*    }}*/}
                                        {/*    fullWidth*/}
                                        {/*/>*/}

                                        <PhoneInput
                                            required
                                            defaultCountry="BD"
                                            inputComponent={CustomPhoneNumberInput}
                                            value={phoneNumberValue}
                                            onChange={setPhoneNumberValue}
                                            inputProps={{
                                                label: "Mobile number"
                                            }}
                                            fullWidth
                                        />

                                        <TextField
                                            required
                                            id="filled-basic-password"
                                            label="Password"
                                            name="password"
                                            variant="filled"
                                            type="password"
                                            InputProps={{
                                                sx              : {borderRadius: "10px"},
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />
                                        <TextField
                                            required
                                            id="filled-basic-confirm_password"
                                            label="Repeat Password"
                                            name="confirm_password"
                                            variant="filled"
                                            type="password"
                                            InputProps={{
                                                sx              : {borderRadius: "10px"},
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />
                                        <div className="checkboxes">
                                            <FormControlLabel
                                                required
                                                checked={termsChecked}
                                                onChange={() => setTermsChecked(!termsChecked)}
                                                control={<Checkbox defaultChecked/>}
                                                label="I agree to the terms and conditions and privacy policy"
                                                sx={{
                                                    "& .MuiSvgIcon-root": {fontSize: 20, color: "purple"},
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="signup-button">
                                        <button type="submit" className="proceed-button" disabled={buttonDisabled}>
                                            { signupButtonText }
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </ThemeProvider>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
export default Signup;
