// dependencies
import {useState, useEffect , useContext} from "react";
import { useNavigate, useSearchParams} from "react-router-dom";
import axios from "axios";

// component
import Header from "../components/Header";
import Footer from "../components/Footer";

//notification components
import AWN from "awesome-notifications"

//context variable
import { UserContext } from '../contexts/UserContext';


// ui
import bluestarter_image from "../assets/images/products/blue-starter.webp";


import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import avro from "../assets/images/icons/avro5.png";
import chrome from "../assets/images/icons/Chromium_Logo.png";
import cpu from "../assets/images/icons/Cpu.png";
import gimp from "../assets/images/icons/Gimp.png";
import gpu from "../assets/images/icons/gpu.png";
import image3 from "../assets/images/icons/image3.png";
import ram from "../assets/images/icons/Ram.png";
import rom from "../assets/images/icons/ROM.png";
import spyder from "../assets/images/icons/spyder.png";
import vscode from "../assets/images/icons/vs-code.png";
import wifi from "../assets/images/icons/WifiHigh.png";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

// import custom phone number input component
import CustomPhoneNumberInput from "../components/CustomPhoneNumberInput";

// import react phone number input things
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import {districtLists} from "../helper/global";

function OrderStarter(props) {
    const theme                           = createTheme({
        components: {
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        fontFamily: "Figtree Regular"
                    },
                },
            },
        },
    });

    const navigate                        = useNavigate();
    const [termsChecked, setTermsChecked] = useState(false);

    const { token , handleLogout , userIsLoggedIn  } = useContext(UserContext);

    // specs specs
    const SpecsItemWithPicture = ({featureName, featureDetail, picture}) => {
        return (
            <div className="featureContainer">
                <div className="featurePicture">
                    <img src={picture} alt="featurePicture"/>
                </div>
                <div className="featureText">
                    <div className="featureName">{featureName}</div>
                    <div className="featureDetail">{featureDetail}</div>
                </div>
            </div>
        );
    };

    let [urlSearchParams] = useSearchParams();

    //states
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [orderplaceText, setOrderplaceText] = useState('Place order');

    useEffect(() => {
        const qsError = urlSearchParams.get("error");
        if (qsError === "protected")
            new AWN().alert("You need to be logged in to access this page", {durations: {success: 0}});
    }, [urlSearchParams]);

    const [quantity, setQuantity] = useState(1);
    const handleChange = (e) => {
        setQuantity(e.target.value);
    };

    const [phoneNumberValue, setPhoneNumberValue] = useState();

    let quantity_options = [
        { id: 1, text: "1" },
        { id: 2, text: "2" },
        { id: 3, text: "3" },
        { id: 4, text: "4" },
        { id: 5, text: "5" },
    ];

    const [district, setDistrict] = useState('Dhaka');
    const handleDistrictChange = (e) => {
        setDistrict(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!termsChecked) {
           alert("Please agree to the terms and conditions and privacy policy");
           return;
        }

        //console.log(e.target);

        const requestData = {
            items          : [
                {
                    id      : 1,
                    quantity: quantity,
                },
            ],
            payment_method : "ONLINE", // COD
            address        : [
                {
                    first_name: e.target.firstName.value,
                    last_name : e.target.lastName.value,
                    address   : e.target.address.value,
                    location  : e.target.location.value,
                    city      : e.target.city.value,
                    post_code : e.target.postcode.value,
                    country   : e.target.country.value,
                    mobile    : phoneNumberValue,
                    district  : district,
                },
            ],
            pay_with_now   : "CARD", // NULL
            delivery_charge: 0,
            partial_payment: true,
        };

        setButtonDisabled(true);
        setOrderplaceText('Order placing, please wait...');


        //send the request to the server api, including the Authorization header with our JWT token in it
        axios
            .post(
                process.env.REACT_APP_API_DOMAIN + "api/v1/marketplace/submit-order",
                requestData,
                {
                    headers: {
                        Authorization : `Bearer ${ token }`,
                        "Content-Type": "application/json",
                    }, // pass the token, if any, to the server
                }
            )
            .then((response) => {

                if(response.data.error){
                    const errorObj = response.data.error;

                    if (errorObj.items) {
                        new AWN().alert(errorObj.items[0], {durations: {success: 0}});
                    }

                    if (errorObj.payment_method) {
                        new AWN().alert(errorObj.payment_method[0], {durations: {success: 0}});
                    }

                    if (errorObj.mobile_number) {
                        new AWN().alert(errorObj.mobile_number[0], {durations: {success: 0}});
                    }

                    setButtonDisabled(false);
                    setOrderplaceText('Place Order');

                }else{
                    if(response.data.success == "false"){

                        new AWN().alert(response.data.error, {durations: {success: 0}});

                        if(response.data.error === "Athuntication error"){
                            handleLogout();
                            navigate("/login?error=protected&redirectto=order-starter");
                        }
                    }else{
                        const redirect = response.data.payment_url;
                        if(redirect){
                            window.location.href = redirect;
                        }else{
                            new AWN().alert("System Error. We are working on this.", {durations: {success: 0}});
                        }
                    }
                }

            })
            .catch((err) => {
                setButtonDisabled(false);
                setOrderplaceText('Place Order');

                console.log(err);
                alert("Error happened, please refresh this page.");
            });
    };

    if (userIsLoggedIn)
        return (
            <div className="order_wrapper">
                <Header/>
                <div className="order">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="container-wrap">
                                    <div className="order-details">
                                        <img src={bluestarter_image} alt="Jadupc Starter" title="Jadupc Starter" />
                                        <div className="jadupc-starter-5000">
                                            <div className="jadupc-starter-text">
                                                JaduPc <span id="starter-highlight">Starter</span>
                                            </div>
                                            {/*<div className="jadupc-starter-price">TK 5,000 + Vat</div>*/}
                                            <div className="jadupc-starter-price">5,000 TK + (375)Vat</div>
                                        </div>
                                        <div className="specs">
                                            <div className="hardware-specs">
                                                <div className="title">Hardware specifications</div>
                                                <div className="specs-container">
                                                    <SpecsItemWithPicture
                                                        featureName="1.8GHz"
                                                        featureDetail="Quad-core processor"
                                                        picture={cpu}
                                                    />
                                                    <SpecsItemWithPicture
                                                        featureName="32GB"
                                                        featureDetail="DDR3 ROM"
                                                        picture={rom}
                                                    />
                                                    <SpecsItemWithPicture
                                                        featureName="3GB"
                                                        featureDetail="DDR3 RAM"
                                                        picture={ram}
                                                    />
                                                    <SpecsItemWithPicture
                                                        featureName="2.4GHz"
                                                        featureDetail="Wi-Fi"
                                                        picture={wifi}
                                                    />
                                                    <SpecsItemWithPicture
                                                        featureName="Mail T720"
                                                        featureDetail="GPU"
                                                        picture={gpu}
                                                    />
                                                </div>
                                            </div>
                                            <div className="preinstalled-software">
                                                <div className="title">Preinstalled software</div>
                                                <div className="software-logos">
                                                    <img src={vscode} alt=""/>
                                                    <img src={avro} alt=""/>
                                                    <img src={chrome} alt=""/>
                                                    <img src={gimp} alt=""/>
                                                    <img src={image3} alt=""/>
                                                    <img src={spyder} alt=""/>
                                                </div>
                                            </div>
                                            <div className="operating-system">
                                                <div className="title">Operating system</div>
                                                <div className="description">
                                                    JaduPc runs on <span id="starter-highlight">ShopnoOS</span> -
                                                    a custom Linux-based operating system. It is based on the
                                                    Debian kernel and is designed to provide a user-friendly
                                                    experience for users who are familiar with the Windows
                                                    operating system.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <form className="order-form" onSubmit={handleSubmit}>
                                        <div className="form-header">Place an order</div>
                                        <div className="title">Pre-orders starting soon</div>

                                        {/*<ThemeProvider theme={theme}>*/}
                                        {/*    <div className="form-fields">*/}
                                        {/*        <TextField*/}
                                        {/*            required*/}
                                        {/*            id="filled-basic-fname"*/}
                                        {/*            label="First name"*/}
                                        {/*            name="firstName"*/}
                                        {/*            variant="filled"*/}
                                        {/*            InputProps={{*/}
                                        {/*                sx              : {borderRadius: "10px", fontFamily: "Figtree Regular"},*/}
                                        {/*                disableUnderline: true,*/}
                                        {/*            }}*/}
                                        {/*        />*/}
                                        {/*        <TextField*/}
                                        {/*            required*/}
                                        {/*            id="filled-basic-lname"*/}
                                        {/*            label="Last name"*/}
                                        {/*            name="lastName"*/}
                                        {/*            variant="filled"*/}
                                        {/*            InputProps={{*/}
                                        {/*                sx              : {borderRadius: "10px", fontFamily: "Figtree Regular"},*/}
                                        {/*                disableUnderline: true,*/}
                                        {/*            }}*/}
                                        {/*        />*/}

                                        {/*        <FormControl fullWidth>*/}
                                        {/*            <InputLabel id="quantity-select-label">Quantity</InputLabel>*/}
                                        {/*            <Select*/}
                                        {/*                labelId="quantity-select-label"*/}
                                        {/*                id="quantity-select"*/}
                                        {/*                value={quantity}*/}
                                        {/*                label="quantity"*/}
                                        {/*                name="quantity"*/}
                                        {/*                onChange={handleChange}*/}
                                        {/*            >*/}
                                        {/*                {quantity_options.map(item => {*/}
                                        {/*                    return (<MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>);*/}
                                        {/*                })}*/}

                                        {/*            </Select>*/}
                                        {/*        </FormControl>*/}

                                        {/*        <PhoneInput*/}
                                        {/*            required*/}
                                        {/*            defaultCountry="BD"*/}
                                        {/*            inputComponent={CustomPhoneNumberInput}*/}
                                        {/*            inputProps={{*/}
                                        {/*                label: "Phone number (Whatsapp)"*/}
                                        {/*            }}*/}
                                        {/*            value={phoneNumberValue}*/}
                                        {/*            onChange={setPhoneNumberValue}*/}
                                        {/*            fullWidth*/}
                                        {/*        />*/}

                                        {/*        <TextField*/}
                                        {/*            required*/}
                                        {/*            id="filled-basic-email"*/}
                                        {/*            type="email"*/}
                                        {/*            label="Email"*/}
                                        {/*            name="email"*/}
                                        {/*            variant="filled"*/}
                                        {/*            InputProps={{*/}
                                        {/*                sx              : {borderRadius: "10px", fontFamily: "Figtree Regular"},*/}
                                        {/*                disableUnderline: true,*/}
                                        {/*            }}*/}
                                        {/*        />*/}

                                        {/*        <FormControl fullWidth>*/}
                                        {/*            <InputLabel id="district-select-label">District</InputLabel>*/}
                                        {/*            <Select*/}
                                        {/*                labelId="district-select-label"*/}
                                        {/*                id="district-select"*/}
                                        {/*                value={district}*/}
                                        {/*                label="District"*/}
                                        {/*                name="district"*/}
                                        {/*                onChange={handleDistrictChange}*/}
                                        {/*            >*/}
                                        {/*                {districtLists.map(item => {*/}
                                        {/*                    return (<MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>);*/}
                                        {/*                })}*/}

                                        {/*            </Select>*/}
                                        {/*        </FormControl>*/}

                                        {/*        <TextField*/}
                                        {/*            required*/}
                                        {/*            id="filled-basic-add1"*/}
                                        {/*            label="Address line 1"*/}
                                        {/*            name="address"*/}
                                        {/*            variant="filled"*/}
                                        {/*            InputProps={{*/}
                                        {/*                sx              : {borderRadius: "10px", fontFamily: "Figtree Regular"},*/}
                                        {/*                disableUnderline: true,*/}
                                        {/*            }}*/}
                                        {/*        />*/}
                                        {/*        <TextField*/}
                                        {/*            required*/}
                                        {/*            id="filled-basic-loc"*/}
                                        {/*            label="Location"*/}
                                        {/*            name="location"*/}
                                        {/*            variant="filled"*/}
                                        {/*            InputProps={{*/}
                                        {/*                sx              : {borderRadius: "10px", fontFamily: "Figtree Regular"},*/}
                                        {/*                disableUnderline: true,*/}
                                        {/*            }}*/}
                                        {/*        />*/}
                                        {/*        <TextField*/}
                                        {/*            required*/}
                                        {/*            id="filled-basic-city"*/}
                                        {/*            label="City"*/}
                                        {/*            name="city"*/}
                                        {/*            variant="filled"*/}
                                        {/*            InputProps={{*/}
                                        {/*                sx              : {borderRadius: "10px", fontFamily: "Figtree Regular"},*/}
                                        {/*                disableUnderline: true,*/}
                                        {/*            }}*/}
                                        {/*        />*/}
                                        {/*        <TextField*/}
                                        {/*            required*/}
                                        {/*            id="filled-basic-postcode"*/}
                                        {/*            type="number"*/}
                                        {/*            label="Postcode"*/}
                                        {/*            name="postcode"*/}
                                        {/*            variant="filled"*/}
                                        {/*            InputProps={{*/}
                                        {/*                sx              : {borderRadius: "10px", fontFamily: "Figtree Regular"},*/}
                                        {/*                disableUnderline: true,*/}
                                        {/*            }}*/}
                                        {/*        />*/}
                                        {/*        <TextField*/}
                                        {/*            required*/}
                                        {/*            id="filled-basic-country"*/}
                                        {/*            label="Country"*/}
                                        {/*            name="country"*/}
                                        {/*            variant="filled"*/}
                                        {/*            InputProps={{*/}
                                        {/*                sx              : {borderRadius: "10px", fontFamily: "Figtree Regular"},*/}
                                        {/*                disableUnderline: true,*/}
                                        {/*            }}*/}
                                        {/*        />*/}
                                        {/*        <div className="checkboxes">*/}
                                        {/*            <FormControlLabel*/}
                                        {/*                required*/}
                                        {/*                checked={termsChecked}*/}
                                        {/*                onChange={() => setTermsChecked(!termsChecked)}*/}
                                        {/*                control={<Checkbox />}*/}
                                        {/*                label="I agree to the terms and conditions and privacy policy"*/}
                                        {/*            />*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</ThemeProvider>*/}
                                        {/*<input type="submit" disabled={buttonDisabled}  value={orderplaceText} className="proceed-button" />*/}

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
}export default OrderStarter;
