import bluestarter_image from "../assets/images/products/blue-starter.webp";
import SpecsItem from "./SpecsItem";
import {Link} from "react-router-dom";
import pro_image from "../assets/images/products/pro.webp";
import React from "react";

function ProductVariants(){
    return (
        <section className="variants" id="variants">
            <div className="variant-header">Pre-Order JaduPc</div>
            <div className="variant-container">
                <div className="variant-item" id="starter">
                    <img src={bluestarter_image} alt=""/>
                    <div className="title-text">
                        <div className="name-price">
                            <div className="device-name">
                                JaduPc <span id="starter-highlight">Starter</span>
                            </div>
                            <div className="booking-fee">Book with only 10%</div>
                        </div>
                        {/*<div className="price">5,000 TK + Vat</div>*/}
                        <div className="price">5,000 TK + (375)Vat</div>
                    </div>

                    <div className="specs-container">
                        <SpecsItem
                            featureName="1.8GHz"
                            featureDetail="Quad-core processor"
                        />
                        <SpecsItem featureName="3GB" featureDetail="DDR3 Ram"/>
                        <SpecsItem featureName="32GB" featureDetail="Storage"/>
                        {/* <SpecsItem
                featureName="Upto 1TB"
                featureDetail="Expandable storage"
              /> */}
                    </div>
                    <div className="buttons">
                        <Link to="/order-starter" className="order-button" id="starter-order">
                            Pre-Order Now
                        </Link>
                        {/*<Link to="#" className="order-button" id="starter-order">
                            Pre-orders starting soon
                        </Link>*/}
                    </div>
                </div>
                <div className="variant-item" id="pro">
                    <img src={pro_image} alt=""/>
                    <div className="title-text">
                        <div className="name-price">
                            <div className="device-name">
                                JaduPc <span id="pro-highlight">Pro</span>
                            </div>
                            <div className="booking-fee">Book with only 10%</div>
                        </div>
                        {/*<div className="price">10,000 TK + Vat</div>*/}
                        <div className="price">Coming Soon</div>
                    </div>

                    <div className="specs-container">
                        <SpecsItem
                            featureName="2.6GHz"
                            featureDetail="Dual-core processor"
                        />
                        <SpecsItem featureName="8GB" featureDetail="DDR3 Ram"/>
                        <SpecsItem featureName="128GB" featureDetail="Storage"/>
                        {/* <SpecsItem
                featureName="Bluetooth & Dual band Wifi"
                featureDetail=""
              /> */}
                    </div>
                    <div className="buttons">
                        <Link to="/order-pro" className="order-button" id="pro-order">
                            Pre-Order Now
                        </Link>
                        {/*<Link to="#" className="order-button" id="pro-order">
                            Pre-orders starting soon
                        </Link>*/}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProductVariants;