// dependencies
import React, {useState, useEffect , useContext} from "react";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {motion, AnimatePresence} from "framer-motion";
import Cookies from "js-cookie";

//context variable
import { UserContext } from '../contexts/UserContext';

import { Link } from 'react-router-dom';
// ui
//import "../css/OrderDetail.css";
//import "../assets/css/style.css";

// components
import Header from "../components/Header";
import Footer from "../components/Footer";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import SchoolIcon from "@mui/icons-material/School";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LogoutIcon from "@mui/icons-material/Logout";

function OrderDetail() {

    const {orderID}                 = useParams();
    const [orderData, setOrderData] = useState(null);
    const { token , handleLogout , userIsLoggedIn  } = useContext(UserContext);
    const navigate                        = useNavigate();


    //console.log(orderID);

    const handleLogOut = () => {
        handleLogout();
        navigate("/login");
    };
    const handleClick = () => {
        navigate("/dashboard");
    };

    useEffect(() => {
        // Make the API call here
        if(token){
            const url = process.env.REACT_APP_API_DOMAIN + `api/v1/marketplace/myorder/${orderID}`
            axios
                .get(url, {
                    headers: {
                        Authorization : `Bearer ${ token }`,
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    // Assuming your data structure is similar to the provided JSON
                    setOrderData(response.data.data);
                })
                .catch((error) => {
                    console.error("Error fetching order data:", error);
                });
        }

    }, []);

    const TabItem = ({icon: Icon, color, text}) => {
        return (
            <div className="tab-item">
                <Icon
                    style={{
                        color   : color || "black",
                        fontSize: "1.5rem",
                    }}
                />
                <div className="tabItem-text">{text}</div>
            </div>
        );
    };

    if (userIsLoggedIn)
        return (
            <div className="dashboard">
                <Header/>
                <div className="rows">
                    <div className="banner"><h1 className="banner-heading">Order detail</h1></div>
                    <div className="columns">
                        <div className="tabs">

                            <button onClick={() => handleClick()}>
                                <TabItem icon={ShoppingCartIcon} text="My Orders"/>
                            </button>

                            <button id="logout-button" onClick={() => handleLogOut()}>
                                <TabItem icon={LogoutIcon} text="Logout"/>
                            </button>
                        </div>
                        <div className="child-container">
                            <div className="detail order-item">
                                {orderData ? (
                                    <div>
                                        <p><b>Product:</b> {orderData.products[0].title}</p>
                                        <p><b>Order ID:</b> {orderData.order_id}</p>
                                        <p><b>Quantity:</b> {orderData.items[0].item_quantity}</p>
                                        <p><b>Booking amount paid:</b> {orderData.payments[0].amount}</p>
                                        <p><b>Amount to be paid:</b> {orderData.total_amount - orderData.payments[0].amount}</p>
                                        <p><b>Total amount:</b> {orderData.total_amount}</p>
                                    </div>
                                ) : (
                                    <p>Loading...</p>
                                )}
                            </div>
                        </div>
                    </div>

                </div>
                <Footer/>
            </div>

        );
    //else return <Navigate to="/login?error=protected"/>;
}

export default OrderDetail;
