// dependencies
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import axios from "axios";

//notification components
import AWN from "awesome-notifications"


import Header from "../components/Header";
import Footer from "../components/Footer";


import {createTheme, ThemeProvider} from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const theme = createTheme({
    components: {
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontFamily: "Figtree Regular",
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                label: {
                    fontFamily: "Figtree Regular",
                },
                input: {
                    fontFamily: "Figtree Regular",
                },
            },
        },
    },
});

function ResetPassword(props) {

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [resetButtonText, setResetButtonText] = useState('Submit');

    const navigate = useNavigate();

    // what to do when the user clicks the submit buton on the form
    const handleSubmit = async (e) => {
        // prevent the HTML form from actually submitting... we use React's javascript code instead
        e.preventDefault();

        try {
            const queryParameters = new URLSearchParams(window.location.search);
            const token = queryParameters.get("token");
            const email = queryParameters.get("email");

            if (!token) {
                new AWN().alert("Token isn't present!", {durations: {success: 0}})
                return;
            }

            if (!email) {
                new AWN().alert("Email isn't present!", {durations: {success: 0}})
                return;
            }

            // create an object with the data we want to send to the server
            if (e.target.password.value !== e.target.confirm_password.value) {
                new AWN().alert("Confirm password doesn't match", {durations: {success: 0}})
                return;
            } else {

                setButtonDisabled(true);
                setResetButtonText('Processing ...');

                const requestData = {
                    password: e.target.password.value,
                    password_confirmation: e.target.confirm_password.value,
                    token: token,
                    email: email
                };

                const response = await axios.post(
                    process.env.REACT_APP_API_DOMAIN + `api/v1/password/reset`,
                    requestData
                );

                if (response.data.success == true) {
                    new AWN().success("Password reset successful, please login.", {durations: {success: 0}})
                    navigate("/login");
                } else if (response.data.success == false) {
                    new AWN().alert("Password reset is not successful", {durations: {success: 0}})
                    setButtonDisabled(false);
                    setResetButtonText('Submit');
                }
            }
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error) {
                const errorObj = err.response.data.error;

                if (errorObj.email) {
                    new AWN().alert(errorObj.email[0], {durations: {success: 0}})
                    setButtonDisabled(false);
                    setResetButtonText('Submit');
                }

                if (errorObj.password) {
                    new AWN().alert(errorObj.password[0], {durations: {success: 0}})
                    setButtonDisabled(false);
                    setResetButtonText('Submit');
                }
            }
        }
    };


    return (
        <div className="signup">
            <Header/>
            <div className="container">
                <div className="row">
                    <div className="offset-md-3 col-md-6">
                        {/*<Blob />*/}
                        <ThemeProvider theme={theme}>
                            <form onSubmit={handleSubmit}>
                                <div className="signup-container">
                                    <div className="signup-title">Forget Password</div>
                                    <div className="signup-fields">
                                        <TextField
                                            required
                                            id="filled-basic"
                                            label="Password"
                                            name="password"
                                            variant="filled"
                                            type="password"
                                            InputProps={{
                                                sx: {borderRadius: "10px"},
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />
                                        <TextField
                                            required
                                            id="filled-basic"
                                            label="Repeat Password"
                                            name="confirm_password"
                                            variant="filled"
                                            type="password"
                                            InputProps={{
                                                sx: {borderRadius: "10px"},
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />
                                    </div>
                                    <div className="signup-button">
                                        <button type="submit" className="proceed-button" disabled={buttonDisabled}>
                                            {resetButtonText}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </ThemeProvider>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );

}

export default ResetPassword;
