import React, {createContext, useState} from "react";
import Cookies from "js-cookie";

export const UserContext = createContext();

/**
 * This Context is used for user login funtionality
 *
 * @param {*} props
 */
const UserContextProvider = (props) => {
    const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
    const [token, setToken] = useState("");
    const [name, setName] = useState("");
    const [tokenExpireTime, setTokenExpireTime] = useState("");

    /**
     * This component handles the user login functionality
     *
     * @param new_token
     * @param new_name
     * @param valid_time in minutes
     * @param rememberMe
     */
    const handleLogin = (new_token, new_name, valid_time, new_rememberMe) => {
        let current_time = Number(Date.now() / 1000); //seconds
        //set in sate
        setUserIsLoggedIn(true);
        setToken(new_token);
        setName(new_name);
        setTokenExpireTime(Number(valid_time*60)+current_time);//in seconds(min to seconds)


        //console.log("Remember me = ", new_rememberMe);

        //set in cookie
        if(new_rememberMe){
            let valid_time_day = Number(valid_time)/(60*24);//minute to day

            Cookies.set('token', new_token, {expires: valid_time_day}); //temporary
            Cookies.set('name', new_name, {expires: valid_time_day}); //temporary
            Cookies.set('exptime', Number(valid_time*60)+current_time, {expires: valid_time_day}); //temporary
        }


        //console.log("handleLogin called");
    };

    /**
     * This component handles the user logout functionality
     */
    const handleLogout = () => {
        //set in state
        setUserIsLoggedIn(false);

        setToken("");
        setName("");
        setTokenExpireTime("");

        //set in cookie
        Cookies.remove("token");
        Cookies.remove("name");
        Cookies.remove("exptime");

        //console.log("handleLogout called");
    };

    /**
     * Check if user is logged in
     */
    const checkLogin = () => {
        //console.log(token);

        if (token) {
            //if we have in state
            // console.log("Token expire time", tokenExpireTime);
            //console.log("Current time in seconds= ", Date.now() / 1000);


            //if (tokenExpireTime > new Date()) {
            if (Number(tokenExpireTime) > Number(Date.now() / 1000)) {
                //comparison in seconds
                //token in state is not expired yet

                /*setToken("");
                setName("");
                setTokenExpireTime("");

                setUserIsLoggedIn(false);
                return false;*/

                //console.log('user logged in from state variable')

                setUserIsLoggedIn(true);
                return true;

            } else {
                //token in state expired
                //console.log("Token in state expired");

                //set state values
                setToken("");
                setName("");
                setTokenExpireTime("");

                setUserIsLoggedIn(false);

                //set cookie value
                Cookies.remove("token");
                Cookies.remove("name");
                Cookies.remove("exptime");


                return false;

                //return true;
            }
        }
        else if(Cookies.get("token")){
            //check if in cookie (remember me checking)

            setToken(Cookies.get("token"));
            setName(Cookies.get("name"));
            setTokenExpireTime(Number(Cookies.get("exptime")));

            setUserIsLoggedIn(true);

            return true;
        }

        setUserIsLoggedIn(false);
        return false;
    };

    return (
        <UserContext.Provider
            value={{
                userIsLoggedIn,
                token,
                name,
                tokenExpireTime,
                setUserIsLoggedIn,
                setToken,
                setName,
                setTokenExpireTime,
                handleLogin,
                handleLogout,
                checkLogin,
            }}
        >
            {props.children}
        </UserContext.Provider>
    );
};
export default UserContextProvider;
