// dependencies
import {useState, useEffect, useContext} from "react";
import {Link, Navigate, useSearchParams, useNavigate} from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

//notification components
import AWN from "awesome-notifications"

import Header from "../components/Header";
import Footer from "../components/Footer";

import {createTheme, ThemeProvider} from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

//context variable
import {UserContext} from '../contexts/UserContext';

//global functions
import {AxiosDefaultHeader} from '../helper/global';
import {FormControl, FormGroup, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select} from "@mui/material";

// import custom phone number input component
import CustomPhoneNumberInput from "../components/CustomPhoneNumberInput";

// import react phone number input things
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import {districtLists} from "../helper/global";

// MUI theme overrides
const theme = createTheme({
    components: {
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontFamily: "Figtree Regular",
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                label: {
                    fontFamily: "Figtree Regular",
                },
                input: {
                    fontFamily: "Figtree Regular",
                },
            },
        },
    },
});

function ExpressInterestForm() {

    const {userIsLoggedIn, name} = useContext(UserContext);

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [submitButtonText, setSubmitButtonText] = useState('Submit');

    const [otherCheckboxStatus, setOtherCheckboxStatus] = useState(false);

    const navigate = useNavigate();

    const [district, setDistrict] = useState('');
    const handleChange = (e) => {
        setDistrict(e.target.value);
    };

    const [phoneNumberValue, setPhoneNumberValue] = useState();

    const [forWhomValue, setForWhomValue] = useState({
        forWhom: [],
    });
    const handleForWhomCheckbox = (e) => {
        const { value, checked } = e.target;
        const { forWhom } = forWhomValue;

        // console.log(`${value} is ${checked}`);

        if(value === 'Other'){
            setOtherCheckboxStatus(e.target.checked);
        }

        if (checked) {
            setForWhomValue({
                forWhom: [...forWhom, value],
            });
        } else {
            setForWhomValue({
                forWhom: forWhom.filter(
                    (e) => e !== value
                )
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (forWhomValue.forWhom.length === 0) {
            new AWN().alert("Please Select for whom do you need option", {durations: {success: 0}});
            return;
        }

        try {

            let arrayWithoutOther;
            if(forWhomValue.forWhom.includes("Other")){
                 arrayWithoutOther = forWhomValue.forWhom.filter(function (other) {
                    return other !== 'Other';
                });
                arrayWithoutOther.push(e.target.otherText.value);
            }else{
                arrayWithoutOther = forWhomValue.forWhom;
            }

            const requestData = {
                email: e.target.email.value,
                name: e.target.name.value,
                phone: phoneNumberValue,
                age: e.target.age.value,
                gender: e.target.gender.value,
                address: e.target.address.value,
                district: district,
                //forWhom: forWhomValue.forWhom,
                forWhom: arrayWithoutOther,
                model: e.target.model.value,
                unit: e.target.unit.value,
                purpose: e.target.purpose.value,
                profession: e.target.profession.value,
                mIncome: e.target.mIncome.value,
                typeOfWork: e.target.typeOfWork.value,
            };


            // send a POST request with the data to store
            const response = await axios.post(
                process.env.REACT_APP_API_DOMAIN + `api/v1/marketplace/express-interest`,
                requestData
            );

            //console.log(response.data.user);
            //console.log(response.data.user['email']);

            new AWN().success("Registration form submitted successfully with " + response.data.user['email'] + ". We will contact as soon possible", {durations: {success: 0}});
            navigate("/");

        } catch (err) {
            if (err.response && err.response.data && err.response.data.error) {
                const errorObj = err.response.data.error;

                Object.keys(errorObj).forEach(key => {
                    // console.log(errorObj[key][0]);
                    new AWN().alert(errorObj[key][0], {durations: {success: 0}});
                })

                new AWN().alert("Unable to register, please try again.", {durations: {success: 0}})
            }
        }

    };

    return (
        <div className="login">
            <Header/>

            <div id="page_header">
                <h1>JaduPc - Express Interest Form</h1>
            </div>

            <div className="container">
                <div className="row">
                    <div className="offset-md-3 col-md-6">
                        <ThemeProvider theme={theme}>
                            <form onSubmit={handleSubmit}>
                                <div className="login-container">
                                    <div className="login-fields">

                                        <TextField
                                            required
                                            id="email"
                                            type="email"
                                            label="Email"
                                            name="email"
                                            variant="filled"
                                            InputProps={{
                                                sx: {borderRadius: "10px"},
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />

                                        <TextField
                                            required
                                            id="name"
                                            label="Name"
                                            name="name"
                                            variant="filled"
                                            type="text"
                                            InputProps={{
                                                sx: {borderRadius: "10px"},
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />

                                        {/*<TextField*/}
                                        {/*    required*/}
                                        {/*    id="phone"*/}
                                        {/*    label="Phone"*/}
                                        {/*    name="phone"*/}
                                        {/*    variant="filled"*/}
                                        {/*    type="text"*/}
                                        {/*    InputProps={{*/}
                                        {/*        sx: {borderRadius: "10px"},*/}
                                        {/*        disableUnderline: true,*/}
                                        {/*    }}*/}
                                        {/*    fullWidth*/}
                                        {/*/>*/}

                                        <PhoneInput
                                            required
                                            defaultCountry="BD"
                                            inputComponent={CustomPhoneNumberInput}
                                            inputProps={{
                                                label: "Phone"
                                            }}
                                            value={phoneNumberValue}
                                            onChange={setPhoneNumberValue}
                                            fullWidth
                                        />

                                        <TextField
                                            required
                                            id="age"
                                            label="Age"
                                            name="age"
                                            variant="filled"
                                            type="text"
                                            InputProps={{
                                                sx: {borderRadius: "10px"},
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />

                                        <FormControl>
                                            <FormLabel id="gender-radio-buttons-group-label">Gender</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="gender-radio-buttons-group-label"
                                                defaultValue="Male"
                                                name="gender"
                                            >
                                                <FormControlLabel value="Female" control={<Radio />} label="Female" />
                                                <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                                <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                            </RadioGroup>
                                        </FormControl>

                                        <TextField
                                            id="address"
                                            label="Address"
                                            name="address"
                                            variant="filled"
                                            type="text"
                                            InputProps={{
                                                sx: {borderRadius: "10px"},
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />

                                        <FormControl fullWidth>
                                            <InputLabel id="district-select-label">District</InputLabel>
                                            <Select
                                                labelId="district-select-label"
                                                id="district-select"
                                                value={district}
                                                label="District"
                                                name="district"
                                                onChange={handleChange}
                                            >
                                                {districtLists.map(item => {
                                                    return (<MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>);
                                                })}

                                            </Select>
                                        </FormControl>

                                        <FormGroup>
                                            <InputLabel>For whom do you need JaduPc?</InputLabel>
                                            <FormControlLabel control={<Checkbox />} name="forWhom" value="Myself" label="Myself" onChange={handleForWhomCheckbox}/>
                                            <FormControlLabel control={<Checkbox />} name="forWhom" value="For Children" label="For Children" onChange={handleForWhomCheckbox}/>
                                            <FormControlLabel control={<Checkbox />} name="forWhom" value="For Parents" label="For Parents" onChange={handleForWhomCheckbox}/>
                                            <FormControlLabel control={<Checkbox />} name="forWhom" value="For Spouse" label="For Spouse" onChange={handleForWhomCheckbox}/>
                                            <FormControlLabel control={<Checkbox />} name="forWhom" value="For Relatives" label="For Relatives" onChange={handleForWhomCheckbox}/>
                                            <FormControlLabel control={<Checkbox />} name="forWhom" value="For Office" label="For Office" onChange={handleForWhomCheckbox}/>
                                            <FormControlLabel control={<Checkbox />} name="forWhom" value="For School" label="For School" onChange={handleForWhomCheckbox}/>
                                            <FormControlLabel control={<Checkbox />} name="forWhom" value="For Computer Lab/ Club" label="For Computer Lab/ Club" onChange={handleForWhomCheckbox}/>

                                            <FormControlLabel control={<Checkbox />} name="forWhom" value="Other" label="Other" onChange={handleForWhomCheckbox}/>

                                            <TextField id="otherText" label="Other Text" name="otherText" variant="filled" type="text" disabled={!otherCheckboxStatus}
                                                InputProps={{
                                                    sx: {borderRadius: "10px"},
                                                    disableUnderline: true,
                                                }}
                                                fullWidth
                                            />

                                        </FormGroup>

                                        <FormControl>
                                            <FormLabel id="model-radio-buttons-group-label">Preferred JaduPc Model</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="model-radio-buttons-group-label"
                                                defaultValue="JaduPc PRO"
                                                name="model"
                                            >
                                                <FormControlLabel value="JaduPc Starter" control={<Radio />} label="JaduPc Starter" />
                                                <FormControlLabel value="JaduPc PRO" control={<Radio />} label="JaduPc PRO" />
                                                <FormControlLabel value="Both" control={<Radio />} label="Both" />
                                            </RadioGroup>
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel id="unit-radio-buttons-group-label">How many units of JaduPc would you need in 2023?</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="unit-radio-buttons-group-label"
                                                defaultValue="3"
                                                name="unit"
                                            >
                                                <FormControlLabel value="1" control={<Radio />} label="1" />
                                                <FormControlLabel value="2" control={<Radio />} label="2" />
                                                <FormControlLabel value="3" control={<Radio />} label="3" />
                                                <FormControlLabel value="4" control={<Radio />} label="4" />
                                                <FormControlLabel value="5 units or more" control={<Radio />} label="5 units or more" />
                                            </RadioGroup>
                                        </FormControl>


                                        <TextField
                                            id="purpose"
                                            label="Purpose (Why do you want to buy JaduPc?)"
                                            name="purpose"
                                            variant="filled"
                                            type="text"
                                            InputProps={{
                                                sx: {borderRadius: "10px"},
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />

                                        <FormControl>
                                            <FormLabel id="profession-radio-buttons-group-label">What is your profession?</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="profession-radio-buttons-group-label"
                                                defaultValue="Student / Learner"
                                                name="profession"
                                            >
                                                <FormControlLabel value="Student / Learner" control={<Radio />} label="Student / Learner" />
                                                <FormControlLabel value="Freelancer" control={<Radio />} label="Freelancer" />
                                                <FormControlLabel value="Teaching" control={<Radio />} label="Teaching" />
                                                <FormControlLabel value="Entrepreneur/ Startup/ Business" control={<Radio />} label="Entrepreneur/ Startup/ Business" />
                                                <FormControlLabel value="Private Job Holder" control={<Radio />} label="Private Job Holder" />
                                                <FormControlLabel value="Government Job Holder" control={<Radio />} label="Government Job Holder" />
                                                <FormControlLabel value="NGO" control={<Radio />} label="NGO" />
                                                <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                            </RadioGroup>
                                        </FormControl>

                                        <TextField
                                            id="mIncome"
                                            type="text"
                                            label="Monthly Income"
                                            name="mIncome"
                                            variant="filled"
                                            InputProps={{
                                                sx: {borderRadius: "10px"},
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />

                                        <TextField
                                            required
                                            id="typeOfWork"
                                            type="text"
                                            label="Your Employer/ type of work"
                                            name="typeOfWork"
                                            variant="filled"
                                            InputProps={{
                                                sx: {borderRadius: "10px"},
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />

                                    </div>
                                    <div className="login-button">
                                        <button type="submit" className="proceed-button" disabled={buttonDisabled}>
                                            {submitButtonText}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </ThemeProvider>

                    </div>
                </div>
            </div>

            <Footer/>

        </div>
    );
}

export default ExpressInterestForm;
