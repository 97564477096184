// dependencies
import {useState, useEffect, useContext} from "react";
import {Link, Navigate, useSearchParams, useNavigate} from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

//notification components
import AWN from "awesome-notifications"

// components
//import Blob from "../components/Blob";
import Header from "../components/Header";
import Footer from "../components/Footer";

import {createTheme, ThemeProvider} from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

//context variable
import {UserContext} from '../contexts/UserContext';

//global functions
// import { plus } from '../helper/global';
import {AxiosDefaultHeader} from '../helper/global';


// MUI theme overrides
const theme = createTheme({
    components: {
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontFamily: "Figtree Regular",
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                label: {
                    fontFamily: "Figtree Regular",
                },
                input: {
                    fontFamily: "Figtree Regular",
                },
            },
        },
    },
});

/**
 * Login component
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Login(props) {

    const {
        userIsLoggedIn,
        name,
        handleLogin
    } = useContext(UserContext);


    const navigate = useNavigate();

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [loginButtonText, setLoginButtonText] = useState('Login');
    const [redirectto, setRedirectto] = useState('/dashboard');

    const [errorShown, setErrorShown] = useState(false);


    // check for token in cookies and toggle login state
   /* const [isLoggedIn, setIsLoggedIn] = useState(false);

    if (!isLoggedIn && Cookies.get('token')) {
        setIsLoggedIn(true);
    } else if (isLoggedIn && !Cookies.get('token')) {
        setIsLoggedIn(false);
    }*/

    // variable for "remember me" choice
    const [rememberMe, setRememberMe] = useState(true);
    const handleCheckboxChange = (e) => {
        setRememberMe(e.target.checked);
    };

    // for making requests to the server
    const [response, setResponse] = useState({});

    // error message
    //const [errorMessage, setErrorMessage] = useState("");
    let [urlSearchParams] = useSearchParams();


    //show notification(error/success/note)
    useEffect(() => {
        const qsError = urlSearchParams.get("error");
        if (qsError === "protected") {
            if (!errorShown) {
                new AWN().alert("You need to be logged in to access this page", {durations: {success: 0}})
                setErrorShown(true)
            }
        }

        const accountVerified = urlSearchParams.get("email_verified");
        if (accountVerified === "true") {
            new AWN().success("Your email has been verified. Please login", {durations: {success: 0}});
        }else if(accountVerified === "false"){
            new AWN().alert("Confirmation duration is paased. We have sent you an email again, please check your inbox and spam folder, from email click the verify your email address", {durations: {success: 0}});
        }

        const registrationSuccess = urlSearchParams.get("msg");
        if (registrationSuccess === "register_success") {
            new AWN().success("Register successfully, please verify your email and login.", {durations: {success: 0}});
        }


    }, []);

    // make request to server on form submit
    useEffect(() => {
        if (response.token) {

            // for testing purposes
            // console.log(`User successfully logged in: ${response.username}`);
            // cookie expiration

            //let valid_time = Number(response.valid_time)/(60*60*24);//now in day
            //console.log(valid_time);
            //const expiresAfter = rememberMe ? 7 : undefined;  //valid_time  in seconds
            const redirectUrl = urlSearchParams.get("redirectto");

            handleLogin(response.token, response.user.name, response.valid_time, rememberMe);

            if (redirectUrl) {
                navigate('/' + redirectUrl);
            }else{
                navigate(redirectto);
            }

        }
    }, [response.token]);

    // handle form submit
    const handleSubmit = async (e) => {
        e.preventDefault();

        setButtonDisabled(true);
        setLoginButtonText('Sending login info ...');

        //console.log(AxiosDefaultHeader);

        try {
            const requestData = {
                email: e.target.email.value,
                password: e.target.password.value,
            };

            const response = await axios.post(
                process.env.REACT_APP_API_DOMAIN + `api/v1/marketplace/signin`,
                requestData,
                {
                    headers: AxiosDefaultHeader
                }
            );

            //invalid credentials
            if (response.data.success === false) {
                setButtonDisabled(false);
                setLoginButtonText('Login');
                new AWN().alert(response.data.status, {durations: {success: 0}})
            }

            setResponse(response.data);
        } catch (err) {
            new AWN().alert("Login Failed", {durations: {success: 0}})
            setLoginButtonText('Login');
        }
    };

    // return login page if not logged in
    if (!userIsLoggedIn)
        return (
            <div className="login">
                <Header/>
                {/*<Blob />*/}
                <div className="container">
                    <div className="row">
                        <div className="offset-md-3 col-md-6">
                            <ThemeProvider theme={theme}>
                                <form onSubmit={handleSubmit}>
                                    <div className="login-container">
                                        {name}
                                        <div className="login-title">Login to your account</div>
                                        <div className="login-fields">
                                            <TextField
                                                required
                                                id="filled-basic-email"
                                                type="email"
                                                label="Email"
                                                name="email"
                                                variant="filled"
                                                InputProps={{
                                                    sx: {borderRadius: "10px"},
                                                    disableUnderline: true,
                                                }}
                                                fullWidth
                                            />
                                            <TextField
                                                required
                                                id="filled-basic-password"
                                                label="Password"
                                                name="password"
                                                variant="filled"
                                                type="password"
                                                InputProps={{
                                                    sx: {borderRadius: "10px"},
                                                    disableUnderline: true,
                                                }}
                                                fullWidth
                                            />
                                            <div className="checkboxes">
                                                <FormControlLabel
                                                    checked={rememberMe}
                                                    onChange={handleCheckboxChange}
                                                    control={<Checkbox/>}
                                                    label="Remember me"
                                                    sx={{
                                                        "& .MuiSvgIcon-root": {fontSize: 20, color: "purple"},
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="login-button">
                                            <button type="submit" className="proceed-button" disabled={buttonDisabled}>
                                                {loginButtonText}
                                            </button>
                                        </div>
                                        <Link to="/signup">
                                            <div className="signup-link">Don't have an account yet? Sign up here!</div>
                                        </Link>
                                        <div>
                                            Forgot Password? click <Link to="/forget-password">here</Link> to reset
                                        </div>
                                    </div>
                                </form>
                            </ThemeProvider>

                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    //redirect to dashboard if logged in
    else return <Navigate to="/dashboard"/>;
}

export default Login;
