import { forwardRef } from 'react'
import TextField from "@mui/material/TextField";

const CustomPhoneNumberInput = (props, ref) => {

    return (

        <TextField
            {...props}
            inputRef={ref}
            size='small'
            label={props.inputProps.label}
            variant='filled'
            InputProps={{
                sx              : {borderRadius: "10px"},
                disableUnderline: true,
            }}
            name='phone'
        />
    )
}
export default forwardRef(CustomPhoneNumberInput)