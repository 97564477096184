// UI
//import "../../css/Payment.css";
import { Link } from 'react-router-dom';
import '../../assets/css/style.css';

function Success() {
    return (
        <div className="payment">
            <div className="heading">Congratulations!</div>
            <div className="subheading">
                Your <span id="highlight">JaduPC</span> is on it's way.
            </div>

            <Link to="/dashboard">
                <div className="dashboard-link">Return to your dashboard</div>
            </Link>
        </div>
    );
}

export default Success;
