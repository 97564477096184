import Header from "../components/Header";
import Footer from "../components/Footer";
import Cookies from "js-cookie";
import {useContext, useEffect} from "react";
import {UserContext} from "../contexts/UserContext";

// ui
//import "../css/InfoPages.css";
//import "../assets/css/style.css";

function DeliveryPolicy() {
    return (
        <div className="info-page">
            <Header/>
            <div id="page_header">
                <h1>Delivery Policy</h1>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="container-wrap">
                            <div id="page_content">
                                <p>This delivery policy outlines the terms and conditions governing the delivery of
                                    products purchased from JaduPc ("we," "our," or "us"). By placing an order with us,
                                    you agree to the terms and conditions set forth in this policy.</p>
                                <ol>
                                    <li >
                                       <b>Shipping Methods:</b> We offer various shipping methods to
                                        accommodate your preferences and location. The available shipping
                                        options will be displayed during the checkout process. Please note
                                        that the availability of certain shipping methods may vary based on
                                        your delivery address.
                                    </li>
                                    <li >
                                       <b>Estimated Delivery Times:</b> Estimated delivery times will be
                                        provided to you during the checkout process based on the shipping
                                        method you select and your delivery address. Please note that these
                                        are approximate estimates and actual delivery times may vary due to
                                        factors beyond our control.
                                    </li>
                                    <li >
                                        <b>Order Processing and Fulfillment:</b> Once you place an order
                                        and it is successfully processed, our team will work to fulfill your
                                        order as quickly as possible. Order processing times may vary
                                        depending on product availability, order volume, and other factors.
                                        You will receive an email notification with tracking information
                                        when your order is shipped.
                                    </li>
                                    <li >
                                        <b>Shipping Notifications:</b> Once your order has been shipped,
                                        we will send you a shipping confirmation email that includes a
                                        tracking number and a link to track your package's progress. You can
                                        also log into your account on our website to view order status and
                                        tracking information when the dashboard is ready.
                                    </li>
                                    <li >
                                        <b>Shipping Fees:</b> Shipping fees are calculated based on the
                                        shipping method you choose, the destination address, and the
                                        weight/size of the products. The exact shipping fee will be
                                        displayed during the checkout process before you finalize your
                                        order.
                                    </li>
                                    <li >
                                        <b>Delivery Address:</b> Please ensure that the delivery address
                                        provided during checkout is accurate and complete. We are not
                                        responsible for delivery delays or failed deliveries due to
                                        incorrect or incomplete addresses.
                                    </li>
                                    <li >
                                        <b>Undeliverable Packages:</b> In the event that a package is
                                        deemed undeliverable due to incorrect address information, refusal
                                        of delivery, or other reasons, we will attempt to contact you to
                                        resolve the issue. Additional shipping fees may apply for reshipping
                                        undeliverable packages.
                                    </li>
                                    <li >
                                        <b>Contact Us</b> If you have any questions or concerns about
                                        your order, delivery, or shipping options, please contact our
                                        customer service team at hello@jadupc.com.
                                    </li>
                                    <li >
                                        By placing an order with JaduPc, you acknowledge that you have read,
                                        understood, and agreed to the terms and procedures outlined in this
                                        delivery policy.
                                    </li>
                                </ol>
                                <p >Last Updated: 13th August, 2023</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default DeliveryPolicy;
