// components
import Header from "../components/Header";
import Footer from "../components/Footer"
import {useContext, useEffect} from "react";
import {UserContext} from "../contexts/UserContext";
import Cookies from "js-cookie";

// ui
//import "../css/InfoPages.css";
//import "../assets/css/style.css";

function TermsConditions() {
    return (
        <div className="info-page">
            <Header/>
            <div id="page_header">
                <h1>Terms & Conditions</h1>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="container-wrap">
                            <div id="page_content">
                                <p> Welcome to JaduPc! By accessing and using our website or purchasing
                                    our products, you agree to comply with the following terms and
                                    conditions:</p>
                                <ol>
                                    <li>
                                       <b>Product Information:</b> We strive to provide accurate and up-to-date
                                        information about our products, including specifications, features,
                                        and pricing. However, we reserve the right to make changes without
                                        prior notice.
                                    </li>
                                    <li>
                                        <b>Placement:</b> Placing an order with JaduPc constitutes an offer to
                                        purchase our products. All orders are subject to availability and
                                        acceptance by JaduPc.
                                    </li>
                                    <li>
                                        <b>Payment:</b> Payment for orders can be made through our secure online
                                        payment gateway AamarPay. We accept major credit/debit cards and
                                        other forms of electronic payment. Payment must be made before your
                                        order is processed for shipment.
                                    </li>
                                    <li>
                                        <b>Shipping and Delivery:</b> Please refer to our Delivery Policy for
                                        detailed information on shipping methods, delivery times, and
                                        applicable fees.
                                    </li>
                                    <li>
                                        <b>Cancellation:</b> You can cancel your order before it has been
                                        shipped. Refunds for canceled orders will be processed as per our
                                        Refund Policy.
                                    </li>
                                    <li>
                                        <b>Refunds:</b> Refunds will be processed within 3 to 7 business days
                                        from the date of product return approval. The refund will be issued
                                        through the original payment method used for the purchase.
                                    </li>
                                    <li>
                                        <b>Warranty:</b> Our products come with a one year warranty. Please
                                        refer to the product documentation for warranty details.
                                    </li>
                                    <li>
                                        <b>Intellectual Property:</b> All content on the JaduPc website,
                                        including images, text, logos, and graphics, is protected by
                                        intellectual property laws and may not be used without our explicit
                                        permission.
                                    </li>
                                    <li>
                                        <b>Limitation of Liability:</b> JaduPc is not liable for any direct,
                                        indirect, incidental, consequential, or punitive damages arising
                                        from the use or inability to use our products.
                                    </li>
                                    <li>
                                        By using our website and purchasing our products, you acknowledge
                                        that you have read, understood, and agreed to our terms and conditions
                                        and all other relevant policies outlined above. If you have any
                                        questions or concerns, please don't hesitate to contact us at
                                        hello@jadupc.com.
                                    </li>
                                </ol>
                                <p className="list-item">Last Updated: 13th August, 2023</p>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <Footer/>
        </div>
    );
}

export default TermsConditions;
