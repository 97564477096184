// dependencies
import React, {useState, useEffect, useRef, useContext} from "react";
import {Link} from "react-router-dom";
import {useInView} from "framer-motion";
import {motion} from "framer-motion";

// components
import Header from "../components/Header";
import ProductVariants from "../components/ProductVariants";
//import SpecsItem from "../components/SpecsItem";
import CustomAccordion from "../components/Accordion";
//import Blob from "../components/Blob";
import Footer from "../components/Footer";


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from "react-responsive-carousel";

//products images
//import bluestarter_image from "../assets/images/products/blue-starter.webp";
//import pro_image from "../assets/images/products/pro.webp";

import shopnoos from "../assets/images/icons/shopnoos.gif";
import desktop from "../assets/images/jpc-desk.webp";

//partners photos
import brac from "../assets/images/partners/brac.webp";
import efarmer from "../assets/images/partners/efarmer.webp";
import yellowraptor from "../assets/images/partners/yellowraptor.webp";
import ncc from "../assets/images/partners/ncc.webp";
import frontech from "../assets/images/partners/frontech.webp";
import techacademy from "../assets/images/partners/techacademy.webp";
import ekshop from "../assets/images/partners/ekshop.webp";
import ecourier from "../assets/images/partners/ecourier.webp";
import pathao from "../assets/images/partners/pathao.webp";
import amarpay from "../assets/images/partners/amarpay.webp";
import bdosn from "../assets/images/partners/bdosn.webp";
import systech from "../assets/images/partners/systech.webp";
import lws from "../assets/images/partners/lws.webp";
import bitbyte from "../assets/images/partners/bitbyte.webp";
import codeboxr from "../assets/images/partners/codeboxr.webp";

import codeblocks from "../assets/images/icons/codeblocks.png";
import inkscape from "../assets/images/icons/inkscape.png";
import lollypop from "../assets/images/icons/lollypop.png";
import qbittorrent from "../assets/images/icons/qbittorrent.png";
import evince from "../assets/images/icons/evince.png";


import computer from "../assets/images/1.png";
import prothomalo from "../assets/images/icons/prothomalo.png";
import tbs from "../assets/images/icons/tbs.png";

//icons
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import SpeedIcon from "@mui/icons-material/Speed";
import SchoolIcon from "@mui/icons-material/School";
import CodeIcon from "@mui/icons-material/Code";
import ExtensionIcon from "@mui/icons-material/Extension";

import arduinoide from "../assets/images/icons/arduinoide.png";
import avro from "../assets/images/icons/avro5.png";
import chrome from "../assets/images/icons/Chromium_Logo.png";
import genie from "../assets/images/icons/geany.png";
import gimp from "../assets/images/icons/Gimp.png";
import image3 from "../assets/images/icons/image3.png";
import kolourpaint from "../assets/images/icons/kolourpaint.png";
import pdfviewer from "../assets/images/icons/pdgviewer.png";
import spyder from "../assets/images/icons/spyder.png";
import vscode from "../assets/images/icons/vs-code.png";
import whatsapp from "../assets/images/icons/whatsie.png";
import libreoffice from "../assets/images/icons/libreoffice.png";
import librewolf from "../assets/images/icons/librewolf.png";
import parole from "../assets/images/icons/parole.png";
import turbowrap from "../assets/images/icons/turbowrap.png";
import pdf from "../assets/images/icons/pdf.png";
import dictionary from "../assets/images/icons/dictionary.png";
import form from "../assets/images/form.png";
import delivery from "../assets/images/delivery.png";
import wait from "../assets/images/wait.png";
import nlkt from "../assets/images/icons/nlkt.png";


//team members

//founders
import team_founder_1 from "../assets/images/team/founders/1.Masrur Hannan-Founder.webp";
import team_founder_2 from "../assets/images/team/founders/2.Ragib Ehsan-Chief Technology Officer.webp";
import team_founder_3 from "../assets/images/team/founders/3.Tahmid Abir- Chief Product Officer.webp";

//advisors
import team_advisors_1 from "../assets/images/team/advisors/1.naveed-mahbub-chief-advisor.webp";
import team_advisors_2 from "../assets/images/team/advisors/2.saker-ghani.webp";
import team_advisors_4 from "../assets/images/team/advisors/4.hasin-hayder.webp";
import team_advisors_5 from "../assets/images/team/advisors/5.sabuj-kundu.webp";
import team_advisors_6 from "../assets/images/team/advisors/6.sumit-saha.webp";
import team_advisors_7 from "../assets/images/team/advisors/7.al-mahmud.webp";
import team_advisors_8 from "../assets/images/team/advisors/8.fahim-hossain.webp";
import team_advisors_9 from "../assets/images/team/advisors/9.hm--nayem.webp";
import team_advisors_10 from "../assets/images/team/advisors/10.ali-hossain.webp";
import team_advisors_11 from "../assets/images/team/advisors/11.ahsan-ul-haque-helal.webp";


//consultants
import team_consultants_1 from "../assets/images/team/consultants/1.ibtesam-doha-sr-consultant.webp";
import team_consultants_2 from "../assets/images/team/consultants/2.redwan-ferdous-hardware-_-production-expert.webp";
import team_consultants_3 from "../assets/images/team/consultants/3.onindo-ahmed-marketing-and-social-media-expert--.webp";
import team_consultants_4 from "../assets/images/team/consultants/4.dibakar-saha-deep-business-development-consultant.webp";
import team_consultants_5 from "../assets/images/team/consultants/5.fahad-bin-husne-ali-story-teller.webp";

//techs
import team_tech_4 from "../assets/images/team/tech/4.mohimin-al-bhuiyan--production-lead.webp";
import team_tech_5 from "../assets/images/team/tech/5.fahim-abrar-saikat-software-lead.webp";
import team_tech_6 from "../assets/images/team/tech/6.ahm-mubashshir--software-engineer.webp";
//team members


import LanguageIcon from "@mui/icons-material/Language";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import bijoy from "../assets/images/icons/bijoy.png";
//impact slider images
import impact1 from "../assets/images/impact/image1.webp";
import impact2 from "../assets/images/impact/image2.webp";
import impact3 from "../assets/images/impact/image3.webp";
import impact4 from "../assets/images/impact/image4.webp";
import impact5 from "../assets/images/impact/image5.webp";
import impact6 from "../assets/images/impact/image6.webp";
import impact7 from "../assets/images/impact/image7.webp";
/*import impact8 from "../assets/images/impact/image8.webp";
import impact9 from "../assets/images/impact/image9.webp";
import impact10 from "../assets/images/impact/image10.webp";
import impact11 from "../assets/images/impact/image11.webp";
import impact12 from "../assets/images/impact/image12.webp";
import impact13 from "../assets/images/impact/image13.webp";
import impact14 from "../assets/images/impact/image14.webp";
import impact15 from "../assets/images/impact/image15.webp";
import impact16 from "../assets/images/impact/image16.webp";
import impact17 from "../assets/images/impact/image17.webp";
import impact18 from "../assets/images/impact/image18.webp";
import impact19 from "../assets/images/impact/image19.webp";
import impact20 from "../assets/images/impact/image20.webp";
import impact21 from "../assets/images/impact/image21.webp";
import impact22 from "../assets/images/impact/image22.webp";
import impact23 from "../assets/images/impact/image23.webp";
import impact24 from "../assets/images/impact/image24.webp";*/
import impact25 from "../assets/images/impact/image25.webp";
import impact26 from "../assets/images/impact/image26.webp";
import impact27 from "../assets/images/impact/image27.webp";
/*
import impact28 from "../assets/images/impact/image28.webp";
import impact29 from "../assets/images/impact/image29.webp";
import impact30 from "../assets/images/impact/image30.webp";
import impact31 from "../assets/images/impact/image31.webp";
import impact32 from "../assets/images/impact/image32.webp";
import impact33 from "../assets/images/impact/image33.webp";
import impact34 from "../assets/images/impact/image34.webp";
*/

//icons
import NotesIcon from "@mui/icons-material/Notes";
import BorderAllIcon from "@mui/icons-material/BorderAll";
import StorageIcon from "@mui/icons-material/Storage";
import ClassIcon from "@mui/icons-material/Class";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import HubIcon from "@mui/icons-material/Hub";
import MonitorIcon from "@mui/icons-material/Monitor";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import MouseIcon from "@mui/icons-material/Mouse";


//shopno os photos
import ss6 from "../assets/images/shopno-ss/16.webp";
import ss1 from "../assets/images/shopno-ss/11.webp";
import ss2 from "../assets/images/shopno-ss/12.webp";
import ss3 from "../assets/images/shopno-ss/13.webp";
import ss4 from "../assets/images/shopno-ss/14.webp";
import ss5 from "../assets/images/shopno-ss/15.webp";
import ss7 from "../assets/images/shopno-ss/17.webp";
import Cookies from "js-cookie";
import {UserContext} from "../contexts/UserContext";
//import productVariants from "../components/ProductVariants";
//end shopno os photos

const Specs = ({icon: Icon, title, desc}) => {
    return (
        <div className="spec-item">
            {Icon && (
                <Icon
                    style={{
                        color   : "black",
                        fontSize: "1rem",
                    }}
                />
            )}
            <div className="text">
                {title && <div className="title">{title}</div>}
                {desc && <div className="desc">{desc}</div>}
            </div>
        </div>
    );
};

const Software = ({picture, title, desc}) => {
    return (
        <motion.div
            whileHover={{
                scale     : 1.05,
                transition: {duration: 0.3},
            }}
            whileTap={{scale: 0.9}}>
            <div className="software-box">
                <img src={picture} alt=""/>
                <div className="text">
                    {title && <div className="title">{title}</div>}
                    {desc && <div className="desc">{desc}</div>}
                </div>
            </div>
        </motion.div>
    );
};


//var teamGroup = 'founders';

const TeamMember = ({picture, name, designation, link}) => {
    //console.log(link);

    return (
        <div className="team-member">
            <img src={picture} alt={name} />
            <div className="text">
                <div className="name">{name}</div>
                <div className="designation">{designation}</div>

                <a className={link === undefined ? 'hidden' : 'show'} href={link}>
                    <LinkedInIcon
                        style={{
                            color   : "black",
                            fontSize: "2rem",
                        }}
                    />
                </a>
            </div>
        </div>
    );
};

/*function  changeTeamGroup(name){
    console.log("teamGroup", name);
    //teamGroup = name;
    //teamGroup = name;
    setTeamGroup(name);
}*/

function Home() {
    const [teamGroup, setTeamGroup] = useState('founders');
    // b. ref object to check if specs section is in view
    const specsRef      = useRef(null);
    const specsIsInView = useInView(specsRef);

    // c. ref object to check if howto section is in view
    const howtoRef      = useRef(null);
    const howtoIsInView = useInView(howtoRef);

    return (
        <div className="home">
            {/* 0.1 blob */}
            {/*<Blob />*/}
            {/* 0.2 header */}
            <div className="header">
                <Header/>
            </div>
            <ProductVariants/>

            <div id="desktoppkg-wrap" className="home-section home-section-desktoppkg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="section-heading-25 desktoppkg-title">JaduPc Desktop Setup</h2>
                            <div className="description">
                                Now you can get a desktop setup with your JaduPc, for{" "}
                                <b>6,000TK</b> (additional to CPU price).
                            </div>
                            <div className="specs-container">
                                <div className="desktop-feature">
                                    <MonitorIcon
                                        style={{
                                            fontSize: "2rem",
                                        }}
                                    />
                                    <div className="feature-name">19 inch eSonic Monitor</div>
                                </div>
                                <div className="desktop-feature">
                                    <KeyboardIcon
                                        style={{
                                            fontSize: "2rem",
                                        }}
                                    />
                                    <div className="feature-name">Full Size Fastkey Keyboard</div>
                                </div>
                                <div className="desktop-feature">
                                    <MouseIcon
                                        style={{
                                            fontSize: "2rem",
                                        }}
                                    />
                                    <div className="feature-name">Fastkey Mouse</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="image">
                                <img src={desktop} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="howto-wrap" className="home-section home-section-howto">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="center section-heading-25 howto-title">How to Order JaduPc</h2>
                        </div>
                        <div className="col-md-12">
                            <div className="steps-container">
                                <div className="step-item">
                                    <div className="step-image">
                                        <img src={form} alt="step-image"/>
                                    </div>
                                    <div className="text">
                                        <div className="step-title">Pre-Order a JaduPc</div>
                                        <div className="step-desc">
                                            from this website and make the payment online.
                                        </div>
                                    </div>
                                </div>
                                <div className="step-item">
                                    <div className="step-image">
                                        <img src={wait} alt="step image"/>
                                    </div>
                                    <div className="text">
                                        <div className="step-title">JaduPc Production Tracking</div>
                                        <div className="step-desc">
                                            Once your pre-order is received, we wil start the production
                                            work and keep you posted.
                                        </div>
                                    </div>
                                </div>
                                <div className="step-item">
                                    <div className="step-image">
                                        <img src={delivery} img="step image"/>
                                    </div>
                                    <div className="text">
                                        <div className="step-title">JaduPc Shipment Tracking !</div>
                                        <div className="step-desc">
                                            As soon as production and testing done, your JaduPc will be
                                            immediately shipped and you will be able to track the shipment.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="intro-wrap" className="home-section home-section-intro">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="section-heading-25 intro-title">What is JaduPc?</h2>
                            <div className="subheading">
                                JaduPc is a Bangladeshi startup initiative producing low cost
                                computers based on SBCs (single board computers) - running a custom
                                operating system (ShopnoOS) based on Linux that resembles the most
                                popular operating systems of the world to maintain conventions. With
                                its cost-effectiveness and reliability, JaduPc is uniquely designed
                                to cater to youth education, granting opportunities for digital
                                literacy, programming, and even robotics. The core mission of JaduPc
                                is to empower Bangladeshi youth with the essential tools for
                                enhancing their skill sets; and essentially mitigate the digital
                                divide and digital gap.
                            </div>
                            <div className="use-cases">
                                <div className="use-case-item">
                                    <SchoolIcon
                                        style={{
                                            color   : "rgba(0, 0, 0, 0.5)",
                                            fontSize: "2rem",
                                        }}
                                    />
                                    <div className="use-case-text">
                                        Affordable solution for youths and school computer labs
                                    </div>
                                </div>
                                <div className="use-case-item">
                                    <CodeIcon
                                        style={{
                                            color   : "rgba(0, 0, 0, 0.5)",
                                            fontSize: "2rem",
                                        }}
                                    />
                                    <div className="use-case-text">
                                        Great for learning programming and technical skills
                                    </div>
                                </div>
                                <div className="use-case-item">
                                    <ExtensionIcon
                                        style={{
                                            color   : "rgba(0, 0, 0, 0.5)",
                                            fontSize: "2rem",
                                        }}
                                    />
                                    <div className="use-case-text">
                                        Access online content and learn with interactive material
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="image">
                                <img src={computer} alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="os-wrap" className="home-section home-section-os">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="shopno" id="shopno_slider">
                                <Carousel
                                    infiniteLoop
                                    autoPlay
                                    showIndicators={false}
                                    showStatus={false}
                                    className="image-container">
                                    <div>
                                        <img src={ss6} className="picture" alt="Shopno slider" title="Shopno slider"/>
                                    </div>
                                    <div>
                                        <img src={ss1} className="picture" alt="Shopno slider" title="Shopno slider"/>
                                    </div>
                                    <div>
                                        <img src={ss2} className="picture" alt="Shopno slider" title="Shopno slider"/>
                                    </div>
                                    <div>
                                        <img src={ss3} className="picture" alt="Shopno slider" title="Shopno slider"/>
                                    </div>
                                    <div>
                                        <img src={ss4} className="picture" alt="Shopno slider" title="Shopno slider"/>
                                    </div>
                                    <div>
                                        <img src={ss5} className="picture" alt="Shopno slider" title="Shopno slider"/>
                                    </div>

                                    <div>
                                        <img src={ss7} className="picture" alt="Shopno slider" title="Shopno slider"/>
                                    </div>
                                </Carousel>
                                <div className="technical-specifications">
                                    <div className="specs">
                                        <Specs icon={LanguageIcon} title="Web surfing"/>
                                        <Specs icon={NotesIcon} title="Word processing"/>
                                        <Specs icon={BorderAllIcon} title="Spreadsheets"/>
                                        <Specs icon={CodeIcon} title="Programming"/>
                                        <Specs icon={StorageIcon} title="Server hosting"/>
                                        <Specs icon={ClassIcon} title="Online classes"/>
                                        <Specs icon={VideoCallIcon} title="Video conferencing"/>
                                        <Specs icon={AutoStoriesIcon} title="Ebook reader"/>
                                        <Specs icon={PlayCircleIcon} title="Media streaming"/>
                                        <Specs icon={EqualizerIcon} title="Technical software"/>
                                        <Specs icon={AutoAwesomeIcon} title="Photo/video editing"/>
                                        <Specs icon={HubIcon} title="IoT projects"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                        <div className="shopno-text-wrap">
                            <div className="shopno-text">
                                {/*<div className="shopno-header">ShopnoOS</div>*/}
                                <h2 className="section-heading os-title">ShopnoOS</h2>
                                <div className="shopno-description">
                                    <p>ShopnoOS is a custom linux distribution built on the Debian kernel. Shopno OS was <strong>INITIALLY</strong> built on top of Armbian 5.10.x Kernel, which is a tailor-made Debian 11 Bullseye kernel designed for the aarch64 architecture. ShopnoOS comes bundled with every JaduPc unit.</p>

                                    <p>With ShopnoOS it has been possible for JaduPc to deliver an affordable single-board computer with the potential to minimize the digital gap and digital divide in Bangladesh and throughout the world. ShopnoOS
                                        is developed by JaduPc with the primary goal of producing arm based or other low configuration single-board computer hardware to develop a proper computing machine: capable of handling common computing
                                        tasks such as web browsing, word processing, programming, and entertainment.</p>
                                </div>
                                <div className="origin">Origin</div>
                                <div className="origin-description">
                                    <p>Shopno OS was built on top of Armbian 5.10.x Kernel, which is a
                                        tailor-made Debian 11 Bullseye kernel designed for the aarch64
                                        architecture. The kernel was patched extensively to ensure proper
                                        functionality as a single-board computer.</p>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="software-wrap" className="home-section home-section-software">
                <div className="container-sm container-fluid ">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="section-heading-25 software-title">Pre-installed Software</h2>
                            <div className="software-subheading">
                                Your JaduPc comes with a built-in software bundle. So you can get to
                                what's important to you — fast.
                            </div>
                            <div className="software-logos">
                                <Software
                                    picture={arduinoide}
                                    title="Arduino IDE"
                                    desc="Electronics prototyping platform"
                                />
                                <Software picture={genie} title="Geany" desc="Lightweight IDE"/>
                                <Software
                                    picture={spyder}
                                    title="Spyder"
                                    desc="Python development environment"
                                />
                                <Software
                                    picture={turbowrap}
                                    title="Turbowrap"
                                    desc="Scratch3 alternative"
                                />
                                <Software
                                    picture={vscode}
                                    title="Visual Studio Code"
                                    desc="Popular IDE"
                                />
                                <Software picture={nlkt} title="nlkt" desc="Typing tutor"/>
                                <Software
                                    picture={gimp}
                                    title="GIMP"
                                    desc="Image manipulating application"
                                />
                                <Software
                                    picture={kolourpaint}
                                    title="Kolourpaint"
                                    desc="Paint application"
                                />
                                <Software picture={librewolf} title="Librewolf" desc="Browser"/>
                                <Software picture={whatsapp} title="Whatsie" desc="WhatsApp client"/>
                                <Software picture={chrome} title="Chromium" desc="Browser"/>
                                <Software
                                    picture={libreoffice}
                                    title="Libreoffice Suite"
                                    desc="Word processing & office productivity suite"
                                />
                                <Software
                                    picture={dictionary}
                                    title="Dictionary"
                                    desc="Simple dictionary application"
                                />
                                <Software picture={pdf} title="Document Viewer" desc="PDF viewer"/>
                                <Software picture={avro} title="ibus-avro" desc="Avro keyboard"/>
                                <Software picture={bijoy} title="ibus-bijoy" desc="Bijoy keyboard"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="impact-wrap" className="home-section home-section-impact">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="center section-heading-25 impact-title">JaduPC Impact</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Carousel
                                infiniteLoop
                                autoPlay
                                showIndicators={false}
                                showStatus={false}
                                className="image-container">
                                <div>
                                    <img src={impact25} className="picture" alt="slide image 25" />
                                </div>
                                <div>
                                    <img src={impact26} className="picture" alt="slide image 26" />
                                </div>
                                <div>
                                    <img src={impact27} className="picture" alt="slide image 27" />
                                </div>
                                <div>
                                    <img src={impact1} className="picture" alt="slide image 1" />
                                </div>
                                <div>
                                    <img src={impact2} className="picture" alt="slide image 2" />
                                </div>
                                <div>
                                    <img src={impact3} className="picture" alt="slide imag 3" />
                                </div>
                                <div>
                                    <img src={impact4} className="picture" alt="slide image 4" />
                                </div>
                                <div>
                                    <img src={impact5} className="picture" alt="slide image 4" />
                                </div>
                                <div>
                                    <img src={impact6} className="picture" alt="slide image 6" />
                                </div>
                                <div>
                                    <img src={impact7} className="picture" alt="slide image 7" />
                                </div>
                                {/*<div>
                <img src={impact8} className="picture" alt="slide image 8"/>
            </div>
            <div>
                <img src={impact9} className="picture" alt="slide image 9"/>
            </div>
            <div>
                <img src={impact10} className="picture" alt="slide image 10"/>
            </div>
            <div>
                <img src={impact11} className="picture" alt="slide image 11"/>
            </div>
            <div>
                <img src={impact12} className="picture" alt="slide image 12"/>
            </div>
            <div>
                <img src={impact13} className="picture" alt="slide image 13"/>
            </div>
            <div>
                <img src={impact14} className="picture" alt="slide image 14"/>
            </div>
            <div>
                <img src={impact15} className="picture" alt="slide image 15"/>
            </div>
            <div>
                <img src={impact16} className="picture" alt="slide image 16"/>
            </div>
            <div>
                <img src={impact17} className="picture" alt="slide image 17"/>
            </div>
            <div>
                <img src={impact18} className="picture" alt="slide image 18"/>
            </div>
            <div>
                <img src={impact19} className="picture" alt="slide image 19"/>
            </div>
            <div>
                <img src={impact20} className="picture" alt="slide image 20"/>
            </div>
            <div>
                <img src={impact21} className="picture" alt="slide image 21"/>
            </div>
            <div>
                <img src={impact22} className="picture" alt="slide image 22"/>
            </div>
            <div>
                <img src={impact23} className="picture" alt="slide image 23"/>
            </div>
            <div>
                <img src={impact24} className="picture" alt="slide image 24"/>
            </div>

            <div>
                <img src={impact28} className="picture" alt="slide image 28"/>
            </div>
            <div>
                <img src={impact29} className="picture" alt="slide image 29"/>
            </div>
            <div>
                <img src={impact30} className="picture" alt="slide image 30"/>
            </div>
            <div>
                <img src={impact31} className="picture" alt="slide image 31"/>
            </div>
            <div>
                <img src={impact32} className="picture" alt="slide image 32"/>
            </div>
            <div>
                <img src={impact33} className="picture" alt="slide image 33"/>
            </div>
            <div>
                <img src={impact34} className="picture" alt="slide image 34"/>
            </div>*/}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
            <div id="teams-wrap" className="home-section home-section-teams">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="center section-heading-25 -title">Meet the team</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="team-category">
                                <li onClick={() => setTeamGroup('founders')}  className={teamGroup === 'founders'? 'team-category-active' : ''}>Founders</li>
                                <li onClick={() => setTeamGroup('techs')}  className={teamGroup === 'techs'? 'team-category-active' : ''}>Tech</li>
                                <li onClick={() => setTeamGroup('consultants')}  className={teamGroup === 'consultants'? 'team-category-active' : ''}>Consultants</li>
                                <li onClick={() => setTeamGroup('advisors')}  className={teamGroup === 'advisors'? 'team-category-active' : ''}>Advisors</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className={teamGroup === 'founders' ? 'member-container member-container-founders' : 'hidden'}>
                                <TeamMember
                                    picture={team_founder_1}
                                    name="Masrur Hannan"
                                    designation="Founder & UX Lead"
                                    link="https://www.linkedin.com/in/mhannan/"
                                />
                                <TeamMember
                                    picture={team_founder_2}
                                    name="Ragib
            Ehsan"
                                    designation="Chief Technology Officer"
                                    link="https://www.linkedin.com/in/ragib-ehsan/"
                                />
                                <TeamMember
                                    picture={team_founder_3}
                                    name="Tahmid Abir"
                                    designation="Chief Product Officer"
                                    link="https://www.linkedin.com/in/tahmidabir/"
                                />
                            </div>
                            <div className={teamGroup === 'techs' ? 'member-container member-container-techs' : 'hidden'}>
                                <TeamMember
                                    picture={team_tech_4}
                                    name="Mohimin Al Bhuiyan"
                                    designation="Production Lead"
                                />
                                <TeamMember
                                    picture={team_tech_5}
                                    name="Fahim Abrar Saikat"
                                    designation="Software Lead"
                                />
                                <TeamMember
                                    picture={team_tech_6}
                                    name="Ahmad Hasan Mubashshir"
                                    designation="Software Engineer"
                                />
                            </div>
                            <div className={teamGroup === 'consultants' ? 'member-container member-container-consultants' : 'hidden'}>
                                <TeamMember
                                    picture={team_consultants_1}
                                    name="Ibtesam Doha"
                                    designation="Sr. Consultant"
                                />
                                <TeamMember
                                    picture={team_consultants_2}
                                    name="Redwan Ferdous"
                                    designation="Hardware Production Expert"
                                />
                                <TeamMember
                                    picture={team_consultants_3}
                                    name="Onindo Ahmed"
                                    designation="Marketing & Social Media Expert"
                                />
                                <TeamMember
                                    picture={team_consultants_4}
                                    name="Dibakar Saha Deep"
                                    designation="Business Development Consultant"
                                />
                                <TeamMember
                                    picture={team_consultants_5}
                                    name="Fahad Bin Husne Ali"
                                    designation="Story Teller"
                                />
                            </div>
                            <div className={teamGroup === 'advisors' ? 'member-container member-container-advisors' : 'hidden'}>
                                <TeamMember
                                    picture={team_advisors_1}
                                    name="Naveed Mahbub"
                                    designation="Chief Advisor"
                                />
                                <TeamMember
                                    picture={team_advisors_2}
                                    name="Saker Ghani"
                                    designation=""
                                />
                                <TeamMember
                                    picture={team_advisors_4}
                                    name="Hasin Hyder"
                                    designation=""
                                />
                                <TeamMember
                                    picture={team_advisors_5}
                                    name="Sabuj Kundu"
                                    designation=""
                                />
                                <TeamMember
                                    picture={team_advisors_6}
                                    name="Sumit Saha"
                                    designation=""
                                />
                                <TeamMember
                                    picture={team_advisors_7}
                                    name="Al Mahmud"
                                    designation=""
                                />
                                <TeamMember
                                    picture={team_advisors_8}
                                    name="Fahim Hossain"
                                    designation=""
                                />
                                <TeamMember
                                    picture={team_advisors_9}
                                    name="Hm Nayem"
                                    designation=""
                                />
                                <TeamMember
                                    picture={team_advisors_10}
                                    name="Ali Hossain"
                                    designation=""
                                />
                                <TeamMember
                                    picture={team_advisors_11}
                                    name="Ahsan Ul Haque Helal"
                                    designation=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="partners-wrap" className="home-section home-section-partners">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="center section-heading-25 partners-title">Our Partners</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="logos">
                                <img src={brac} alt="Partner Logo" title="Partner Logo"/>
                                <img src={efarmer} alt="Partner Logo" title="Partner Logo"/>
                                <img src={yellowraptor} alt="Partner Logo" title="Partner Logo"/>
                                <img src={ncc} alt="Partner Logo" title="Partner Logo"/>
                                <img src={frontech} alt="Partner Logo" title="Partner Logo"/>
                                <img src={techacademy} alt="Partner Logo" title="Partner Logo"/>
                                <img src={ekshop} alt="Partner Logo" title="Partner Logo"/>
                                <img src={ecourier} alt="Partner Logo" title="Partner Logo"/>
                                <img src={pathao} alt="Partner Logo" title="Partner Logo"/>
                                <img src={amarpay} alt="Partner Logo" title="Partner Logo"/>
                                <img src={bdosn} alt="Partner Logo" title="Partner Logo"/>
                                <img src={systech} alt="Partner Logo" title="Partner Logo"/>
                                <img src={lws} alt="Partner Logo" title="Partner Logo"/>
                                <img src={bitbyte} alt="Partner Logo" title="Partner Logo"/>
                                <img src={codeboxr} alt="Partner Logo" title="Partner Logo"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="features-wrap" className="home-section home-section-features">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="center section-heading-25 features-title">We've been featured!</h2>
                        </div>
                    </div>
                    <div className="row embeds">
                        <div className="col-md-6">
                            <div className="embed embed-responsive embed-responsive-16by9">
                                <iframe
                                    src="https://www.youtube.com/embed/ttYnm8d3EPA"
                                    allow="autoplay; encrypted-media"
                                    title="video"
                                    className="embed-responsive-item"
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="embed embed-responsive embed-responsive-16by9">
                                <iframe
                                    src="https://www.youtube.com/embed/TkEPnmlqQTk"
                                    allow="autoplay; encrypted-media"
                                    title="video"
                                    className="embed-responsive-item"
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="embed embed-responsive embed-responsive-16by9">
                                <iframe
                                    src="https://www.youtube.com/embed/7pnPCv-93yE"
                                    allow="autoplay; encrypted-media"
                                    title="video"
                                    className="embed-responsive-item"
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="embed embed-responsive embed-responsive-16by9">
                                <iframe
                                    src="https://www.youtube.com/embed/IMdiUh_6KMs"
                                    allow="autoplay; encrypted-media"

                                    title="video"
                                    className="embed-responsive-item"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row quotes">
                        <div className="col-md-6">
                            <Link className="quote-wrap" target="_blank" to="https://en.prothomalo.com/bangladesh/Bangladeshi-invents-Tk-5000-‘Jadu-PC’">
                                <div className="quote">
                                    <div className="text">
                                        "Bangladeshi invents Tk 5000 ‘Jadu PC’"
                                    </div>
                                    <div className="author">
                                        <img src={prothomalo} alt="JaduPc in Prothomalo newspaper" />
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-6">
                            <Link className="quote-wrap" target="_blank" to="https://www.tbsnews.net/features/panorama/jadupc-cutting-right-corners-deliver-performance-563854">
                                <div className="quote">
                                    <div className="text">
                                        "JaduPC: Cutting the right corners to deliver on performance"
                                    </div>
                                    <div className="author">
                                        <img src={tbs} alt="JaduPc in Tbsnews" />
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div id="faq-wrap" className="home-section home-section-faq">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="center section-heading-25 features-faq">Want to know more?</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="offset-md-2 col-md-8 col-sm-12">
                            <div className="accordion">
                                <CustomAccordion
                                    question="When will JaduPc pre-orders open?"
                                    answer="We will start accepting pre-orders for JaduPc starting 22 August 2023."
                                    panelNumber={1}
                                />
                                <CustomAccordion
                                    question="How much does it cost?"
                                    answer="You can get a standalone JaduPc CPU starting at TK5000 for the Starter model, or the Pro model for TK10,000. There is also the option to get a desktop package starting from TK6,000, on top of the CPU cost."
                                    panelNumber={2}
                                />
                                <CustomAccordion
                                    question="What does the desktop package come with?"
                                    answer="The desktop package includes a 19 inch monitor, a keyboard, and a mouse."
                                    panelNumber={3}
                                />
                                <CustomAccordion
                                    question="Where can I pre-order JaduPc?"
                                    answer="JaduPc pre-orders will be available exclusively online through the JaduPc website."
                                    panelNumber={4}
                                />
                                <CustomAccordion
                                    question="What payment methods are accepted for pre-orders?"
                                    answer="Payments can be made online through card and bKash."
                                    panelNumber={5}
                                />
                                <CustomAccordion
                                    question="When can I expect my pre-ordered JaduPc to arrive?"
                                    answer="We will begin shipping out pre-orders starting mid September 2023. You'll get an email notification when your JaduPc is on its way."
                                    panelNumber={6}
                                />
                                <CustomAccordion
                                    question="What if there's a problem with my JaduPc?"
                                    answer="JaduPc comes with a 1-year warranty and comprehensive technical support."
                                    panelNumber={7}
                                />
                                <CustomAccordion
                                    question="What is ShopnoOS?"
                                    answer="ShopnoOS- JaduPc's in-house developed Linux-based free and open-source operating system which is designed to provide a user-friendly experience while optimizing performance for budget-friendly hardware. Powered by the Debian kernel and customized for JaduPc, ShopnoOS delivers seamless computing in your daily workflow, offering an experience familiar to popular mainstream operating systems."
                                    panelNumber={8}
                                />
                                <CustomAccordion
                                    question="How do I set up my JaduPc?"
                                    answer="JaduPc comes with a suite of pre-installed software out of the box, including Avro, LibreOffice, Chrome, Firefox, Media Player, GIMP, Kolourpaint, and IDEs like Visual Studio Code, Spyder, Arduino IDE, Turbowarp/Scratch, and CONVAY for online meetings."
                                    panelNumber={9}
                                />
                                <CustomAccordion
                                    question="Is Microsoft Windows included with JaduPc?"
                                    answer="No, JaduPc does not come with Microsoft Windows."
                                    panelNumber={10}
                                />
                                <CustomAccordion
                                    question="Can I edit photos or videos on a JaduPc Starter?"
                                    answer="No, JaduPc Starter does not support memory intensive tasks such as photo or video editing."
                                    panelNumber={11}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
        ;
}

export default Home;
