import React from "react";
import ReactDOM from "react-dom/client";

import "./assets/css/style.css";

import App from "./App";
import reportWebVitals from './reportWebVitals';

import ReactGA from "react-ga4";

const root = ReactDOM.createRoot(document.getElementById("root"));

//ReactGA.initialize("G-RXSQ0HH7M8");
if(process.env.REACT_APP_GA4_ID != ''){
    ReactGA.initialize(process.env.REACT_APP_GA4_ID);
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

if(process.env.REACT_APP_GA4_ID != ''){
    const SendAnalytics = ()=> {
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname,
        });
    }

    reportWebVitals(SendAnalytics);
}
