import { useState, useEffect, useContext } from "react";
import { UserContext } from "../contexts/UserContext";

export function plus(a, b) {
  return a + b;
}

export function minus(a, b) {
  return a - b;
}

/**
 * Default axios header used for any axion request
 *
 * @returns {{"Access-Control-Allow-Origin": string, "Access-Control-Allow-Credentials": boolean, "Access-Control-Max-Age": string}}
 * @constructor
 */
export function AxiosDefaultHeader() {
  return {
    "Content-Type": "'application/json;charset=UTF-8",
    //"Access-Control-Allow-Origin" : "*",
    "Access-Control-Allow-Origin" : process.env.REACT_APP_API_DOMAIN,
    "Access-Control-Allow-Headers" : "*",
    //"Access-Control-Allow-Headers" : "Access-Control-Allow-Headers, Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length, Access-Control-Request-Method, Access-Control-Request-Headers",
    "Access-Control-Allow-Credentials" : true,
    "Access-Control-Max-Age" : "1728000"
  };
}

// export function handleLogin(token, name, expireTime) {
//   const { setUserIsLoggedIn, setToken, setName, setTokenExpireTime } =
//     useContext(UserContext);
// }

export const districtLists = [
    { id: "Dhaka", text: "Dhaka" },
    { id: "Chittagong", text: "Chittagong" },
    { id: "Rajshahi", text: "Rajshahi" },
    { id: "Khulna", text: "Khulna" },
    { id: "Barisal", text: "Barisal" },
    { id: "Sylhet", text: "Sylhet" },
    { id: "Rangpur", text: "Rangpur" },
    { id: "Mymensingh", text: "Mymensingh" },
    { id: "Comilla", text: "Comilla" },
    { id: "Narayanganj", text: "Narayanganj" },
    { id: "Gazipur", text: "Gazipur" },
    { id: "Jessore", text: "Jessore" },
    { id: "Jamalpur", text: "Jamalpur" },
    { id: "Bogra", text: "Bogra" },
    { id: "Tangail", text: "Tangail" },
    { id: "Feni", text: "Feni" },
    { id: "Narsingdi", text: "Narsingdi" },
    { id: "Cox's Bazar", text: "Cox's Bazar" },
    { id: "Narail", text: "Narail" },
    { id: "Pabna", text: "Pabna" },
    { id: "Kushtia", text: "Kushtia" },
    { id: "Satkhira", text: "Satkhira" },
    { id: "Kishoreganj", text: "Kishoreganj" },
    { id: "Sirajganj", text: "Sirajganj" },
    { id: "Bagerhat", text: "Bagerhat" },
    { id: "Chandpur", text: "Chandpur" },
    { id: "Noakhali", text: "Noakhali" },
    { id: "Moulvibazar", text: "Moulvibazar" },
    { id: "Brahmanbaria", text: "Brahmanbaria" },
    { id: "Faridpur", text: "Faridpur" },
    { id: "Natore", text: "Natore" },
    { id: "Magura", text: "Magura" },
    { id: "Lakshmipur", text: "Lakshmipur" },
    { id: "Rangamati", text: "Rangamati" },
    { id: "Kurigram", text: "Kurigram" },
    { id: "Thakurgaon", text: "Thakurgaon" },
    { id: "Panchagarh", text: "Panchagarh" },
    { id: "Habiganj", text: "Habiganj" },
    { id: "Manikganj", text: "Manikganj" },
    { id: "Meherpur", text: "Meherpur" },
    { id: "Munshiganj", text: "Munshiganj" },
    { id: "Naranganj", text: "Naranganj" },
    { id: "Netrokona", text: "Netrokona" },
    { id: "Pirojpur", text: "Pirojpur" },
    { id: "Sherpur", text: "Sherpur" },
    { id: "Sunamganj", text: "Sunamganj" },
    { id: "Bhola", text: "Bhola" },
    { id: "Jhalokathi", text: "Jhalokathi" },
    { id: "Patuakhali", text: "Patuakhali" },
    { id: "Barguna", text: "Barguna" },
    { id: "Bandarban", text: "Bandarban" },
    { id: "Khagrachhari", text: "Khagrachhari" },
    { id: "Gopalganj", text: "Gopalganj" },
    { id: "Madaripur", text: "Madaripur" },
    { id: "Rajbari", text: "Rajbari" },
    { id: "Shariatpur", text: "Shariatpur" },
    { id: "Chuadanga", text: "Chuadanga" },
    { id: "Jhenaidah", text: "Jhenaidah" },
    { id: "Chapainawabganj", text: "Chapainawabganj" },
    { id: "Naogaon", text: "Naogaon" },
    { id: "Dinajpur", text: "Dinajpur" },
    { id: "Gaibandha", text: "Gaibandha" },
    { id: "Lalmonirhat", text: "Lalmonirhat" },
    { id: "Nilphamari", text: "Nilphamari" },
  ];
