// faq accordion on home page
// styles must be applied from the callee's css
import {useState} from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {motion, AnimatePresence} from "framer-motion";
import {createTheme, ThemeProvider} from "@mui/material/styles";

const CustomAccordion = ({panelNumber, question, answer}) => {
    const panelId                 = `${panelNumber}`;
    const [expanded, setExpanded] = useState(false);
    const toggleAccordion         = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };
    const theme                   = createTheme({
        components: {
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        backgroundColor: "transparent",
                    },
                },
            },
        },
    });
    return (
        <ThemeProvider theme={theme}>
            <motion.div
                key="content"
                initial={{opacity: 0, height: 0}}
                animate={{opacity: 1, height: "auto"}}
                exit={{opacity: 0, height: 0}}
                transition={{duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98]}}>
                <Accordion
                    sx={{borderRadius: "10px", border: "none", boxShadow: "none"}}
                    expanded={expanded}
                    onChange={toggleAccordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls={`panel-${panelId}-content`}
                        id={`panel${panelId}-header`}>
                        <Typography
                            sx={{
                                width     : "80%",
                                flexShrink: 0,
                                fontFamily: "Figtree Semi Bold",
                                color     : "black",
                            }}>
                            {question}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AnimatePresence>
                            {expanded && (

                                <Typography sx={{width: "90%", fontFamily: "Figtree Regular"}}>
                                    {answer}
                                </Typography>

                            )}
                        </AnimatePresence>
                    </AccordionDetails>
                </Accordion>
            </motion.div>
        </ThemeProvider>
    );
};

export default CustomAccordion;
