//import '../../css/Payment.css';
import { Link } from 'react-router-dom';
import '../../assets/css/style.css';

function Failed() {
    return (
        <div className='payment'>
            <div className='heading'>
                Sorry, something went wrong.
            </div>
            <div className='subheading'>
                Your order did not go through.
            </div>
            <Link to="/dashboard">
                <div className="dashboard-link">Return to your dashboard</div>
            </Link>
        </div>
    )
}

export default Failed