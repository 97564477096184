// dependencies
import {Link} from "react-router-dom";

// ui
//import "../css/Footer.css";

import logo from "../assets/images/logo.png";

//payment gateways
import bkash from "../assets/images/icons/payment_gateways/bkash.webp";
import dbbl from "../assets/images/icons/payment_gateways/dbbl.webp";
import visa from "../assets/images/icons/payment_gateways/visa.webp";
import mastercard from "../assets/images/icons/payment_gateways/mastercard.webp";
import rocket from "../assets/images/icons/payment_gateways/rocket.webp";
import upay from "../assets/images/icons/payment_gateways/upay.webp";
import nagad from "../assets/images/icons/payment_gateways/nagad.webp";
import okwallet from "../assets/images/icons/payment_gateways/okwallet.webp";


//social media icons
import facebook from "../assets/images/icons/facebook.png";
import linkedin from "../assets/images/icons/linkedin.png";

//regular icons
import MailIcon from "@mui/icons-material/Mail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import BusinessIcon from "@mui/icons-material/Business";

function Footer() {
    return (
        <footer className="footer">
            <div className="rows">
                <div className="payment-methods">
                    <img src={visa} className="payment-logo" alt="visa"/>
                    <img src={mastercard} className="payment-logo" alt="mastercard"/>
                    <img src={dbbl} className="payment-logo" alt="dutchbangla bank"/>
                    <img src={bkash} className="payment-logo" alt="bkash"/>
                    <img src={nagad} className="payment-logo" alt="nagad"/>
                    <img src={rocket} className="payment-logo" alt={"rocket"}/>
                    <img src={upay} className="payment-logo" alt="upay"/>
                    <img src={okwallet} className="payment-logo" alt="okwallet"/>
                </div>
                <div className="text">
                    <div className="links">
                        <Link to="/terms">
                            <span className="link">Terms & Conditions</span>
                        </Link>
                        <span> | </span>
                        <Link to="/cancellation">
                            <span className="link">Cancellation Policy</span>
                        </Link>
                        <span> | </span>
                        <Link to="/privacy">
                            <span className="link">Privacy Policy</span>
                        </Link>
                        <span> | </span>
                        <Link to="/delivery">
                            <span className="link">Delivery Policy</span>
                        </Link>
                        <span> | </span>
                        <Link to="/refund">
                            <span className="link">Refund Policy</span>
                        </Link>
                    </div>
                    <div className="copyright">
                        Copyright © 2022-2023. All rights reserved by JaduPc.
                    </div>
                    <div className="license">Trade License: TRAD/DSCC/018559/2022</div>
                </div>
            </div>

            <div className="columns">
                <div className="column-1">
                    <img className="jadupc-logo" src={logo} alt="logo" />
                    <div className="jadupc-short-intro">
                        Making digital literacy accessible to the youth of Bangladesh. To
                        keep yourself up-to-date with our newest products and services, find
                        us on Facbook and LinkedIn.
                    </div>
                    <div className="social-media">
                        <a href="https://www.facebook.com/jadupc"  target="_blank">
                            <img className="link" src={facebook} alt="facebook"/>
                        </a>
                        <a href="https://bd.linkedin.com/company/jadupc" target="_blank">
                            <img className="link" src={linkedin} alt="linkedin"/>
                        </a>
                    </div>
                </div>

                <div className="column-2">
                    <div className="contact">
                        <MailIcon/> hello@jadupc.com
                    </div>
                    {/*<div className="contact">
                        <PhoneInTalkIcon/> <a href="tel:+8809606990077">+880 9606 990077</a>
                    </div>*/}
                    <div className="contact">
                        <PhoneInTalkIcon/> <a href="tel:+8801331538779">+8801331538779</a>
                    </div>
                    <div className="contact">
                        {/*<LocalPhoneIcon/> +880 1703 650 604 (WhatsApp)*/}
                        <LocalPhoneIcon/> +880 1331 538 779 (WhatsApp)
                    </div>
                    <div className="contact">
                        <BusinessIcon/> 7/1 Paribag, Magnolia Point, B4, Dhaka - 1000
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
