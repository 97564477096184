import Header from "../components/Header";
import Footer from "../components/Footer";
import {useContext, useEffect} from "react";
import {UserContext} from "../contexts/UserContext";
import Cookies from "js-cookie";


function RefundPolicy() {
    return (
        <div className="info-page">
            <Header/>
            <div id="page_header">
                <h1>Refund Policy</h1>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="container-wrap">
                            <div id="page_content">
                                <p> This refund policy outlines the terms and conditions under which
                                    refunds are issued for products purchased from JaduPc ("we", "our",
                                    or "us"). By making a purchase on our website, you agree to comply
                                    with the refund procedures described in this policy.</p>
                                <ol>
                                    <li>
                                       <b>Eligibility for Refunds:</b> We offer refunds under the
                                        following circumstances:
                                        <ul>
                                            <li className="indent">
                                                <b>Defective or Damaged Products:</b> If you receive a product that
                                                is defective, damaged, or not as described, you may be eligible for
                                                a refund. Please contact our customer service team within 3 to 7
                                                days of receiving the product to initiate the refund process.
                                            </li>
                                            <li className="indent">
                                                <b>Non-Delivery:</b> If your order does not arrive within the
                                                estimated delivery time and all tracking information indicates
                                                non-delivery, you may be eligible for a refund. Please contact us
                                                within 7 days of the expected delivery date to initiate a refund
                                                request.
                                            </li>
                                        </ul>
                                    </li>
                                    
                                    <li>
                                        <b>Refund Process:</b> To request a refund, follow these steps:
                                        <ul>
                                            <li className="indent">
                                                Contact our customer service team at hello@jadupc.com within 7
                                                working days.
                                            </li>
                                            <li className="indent">
                                                Provide your order number, a description of the issue, and any
                                                relevant supporting documentation (such as photos of the damaged
                                                product).
                                            </li>
                                            <li className="indent">
                                                Our customer service team will review your request and provide
                                                instructions for returning the product, if necessary.
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                       <b>Return of Products:</b> In some cases, you may be required to
                                        return the defective or damaged product to us. We will provide you
                                        with instructions for returning the product, including a return
                                        shipping label if applicable.
                                    </li>
                                    <li>
                                       <b>Refund Amount:</b> The refund amount will be determined based
                                        on the nature of the issue:
                                        <ul>
                                            <li className="indent">
                                                For defective or damaged products, we will issue a full refund,
                                                including the original shipping fees.
                                            </li>
                                            <li className="indent">
                                                For non-delivery cases, we will issue a full refund, including the
                                                original shipping fees.
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        <b>Refund Processing Time:</b> Refunds will be processed within 3
                                        to 7 business days from the date we receive the returned product or
                                        approve your refund request, whichever is later. The refund will be
                                        issued through the original payment method used for the purchase.
                                    </li>
                                    <li>
                                        7) <b>Exceptions:</b> Refunds will not be issued under the following
                                        circumstances:
                                        <ul>
                                            <li className="indent">
                                                Change of mind or buyer's remorse.
                                            </li>
                                            <li className="indent">
                                                Products damaged due to improper use, mishandling, or negligence.
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        Products with minor cosmetic imperfections that do not affect
                                        functionality.
                                    </li>
                                    <li>
                                        By making a purchase from JaduPc, you acknowledge that you have
                                        read, understood, and agreed to the terms and procedures outlined in
                                        this refund policy.
                                    </li>
                                </ol>
                                <p className="list-item">Last Updated: 13th August, 2023</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default RefundPolicy;
