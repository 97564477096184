// dependencies
import {useState, useEffect, useContext} from "react";
import {Link, Navigate, useSearchParams} from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

// components
import Header from "../components/Header";
import Footer from "../components/Footer";
import CustomAccordion from "../components/Accordion";
import ProductVariants from "../components/ProductVariants";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import SchoolIcon from "@mui/icons-material/School";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import LogoutIcon from "@mui/icons-material/Logout";

//context variable
import { UserContext } from '../contexts/UserContext';

import React from "react";

//notification components
import AWN from "awesome-notifications"
//import bluestarter_image from "../assets/images/products/blue-starter.webp";
//import SpecsItem from "../components/SpecsItem";
//import pro_image from "../assets/images/products/pro.webp";
//import ProductVariants from "../components/ProductVariants";

const TabItem = ({icon: Icon, color, text}) => {
    return (
        <div className="tab-item">
            <Icon
                style={{
                    color   : color || "black",
                    fontSize: "1.5rem",
                }}
            />
            <div className="tabItem-text">{text}</div>
        </div>
    );
};

function Dashboard(props) {

    const { name , token , handleLogout , userIsLoggedIn  } = useContext(UserContext);


    //const [userName, setuserName]     = useState(name);
    // const [isLoggedIn, setIsLoggedIn] = useState(userIsLoggedIn);
    // if (!isLoggedIn && Cookies.get("token")) {
    //     setIsLoggedIn(true);
    // } else if (isLoggedIn && !Cookies.get("token")) {
    //     setIsLoggedIn(false);
    // }
    // console.log(isLoggedIn);

    //const local_token  = Cookies.get("token");

    const [selectedTab, setSelectedTab] = useState(1);
    const handleButtonClick = (tabIndex) => {
        setSelectedTab(tabIndex);
    };

    const handleLogOut = () => {
        handleLogout();
        window.location.href = "/login";
    };

    const OrderItem = ({orderID, total_amount, name, item_id}) => {
        let productTitle = null;
        let productDesc  = null;

        if (item_id === 1) {
            productTitle = "JaduPc Starter";
            productDesc  = "Starter description";
        } else if (item_id === 2) {
            productTitle = "JaduPc Pro";
            productDesc  = "Pro description";
        }

        return (
            <div className="order-item">
                <div className="order-number">
                    <div className="order-title">Order number</div>
                    <div className="orderID">{orderID}</div>
                </div>
                <div className="product">
                    <div className="product-title">{productTitle}</div>
                    <div className="product-desc">{productDesc}</div>
                </div>
                <div className="buttons">
                    <Link to={`/order/${orderID}`}>
                        <div className="button">Order details</div>
                    </Link>
                </div>
            </div>
        );
    };
    let [urlSearchParams]                 = useSearchParams();
    useEffect(() => {
        const msgAlert = urlSearchParams.get("msg");
        if (msgAlert === "payment_success"){
            new AWN().success("Payment Successful", {durations: {success: 0}})
        }        
        else if(msgAlert === "payment_fail"){
            new AWN().alert("Payment failed or cancelled", {durations: {success: 0}})
        }
    }, []);

    const [orders, setOrders] = useState([]);

    useEffect(() => {
        if(token){
            axios
                .get(process.env.REACT_APP_API_DOMAIN + "api/v1/marketplace/myorder", {
                    headers: {
                        Authorization : `Bearer ${token}`,
                        //Authorization : `Bearer token}`,
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    const data = response.data.data;
                    setOrders(data);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }

        
    }, [token]);

    if (userIsLoggedIn)
        return (
            <div className="dashboard">
                <Header/>
                <div className="rows">
                    <div className="banner">
                        <h1 className="banner-heading">Welcome, {name}!</h1>
                    </div>
                    <div className="columns">
                        <div className="tabs">
                            <button
                                onClick={() => handleButtonClick(1)}
                                id={`tab-${selectedTab === 1 ? "active" : "inactive"}`}>
                                <TabItem icon={ShoppingCartIcon} text="My Orders"/>
                            </button>
                            <button
                                onClick={() => handleButtonClick(2)}
                                id={`tab-${selectedTab === 2 ? "active" : "inactive"}`}>
                                <TabItem icon={HelpCenterIcon} text="FAQ"/>
                            </button>
                            <button
                                onClick={() => handleButtonClick(3)}
                                id={`tab-${selectedTab === 3 ? "active" : "inactive"}`}>
                                <TabItem icon={SmartDisplayIcon} text="Tutorials"/>
                            </button>
                            <button
                                onClick={() => handleButtonClick(4)}
                                id={`tab-${selectedTab === 4 ? "active" : "inactive"}`}>
                                <TabItem icon={SchoolIcon} text="Resources"/>
                            </button>
                            <button
                                onClick={() => handleButtonClick(5)}
                                id={`tab-${selectedTab === 5 ? "active" : "inactive"}`}>
                                <TabItem icon={SupportAgentIcon} text="Support"/>
                            </button>
                            <button id="logout-button" onClick={() => handleLogOut()}>
                                <TabItem icon={LogoutIcon} text="Logout"/>
                            </button>
                        </div>
                        <div className="child-container">
                            {selectedTab === 1 && orders && (
                                <div className="orders">
                                    {orders.length > 0 ? (
                                        orders.map((order) => (
                                            <OrderItem
                                                key={order.id}
                                                orderID={order.order_id}
                                                total_amount={order.total_amount}
                                                name={order.delivery_address[0].first_name}
                                                item_id={order.item_id}
                                            />
                                        ))
                                    ) : (
                                        <div className="no-orders-wrap">
                                            <div className="no-orders">No orders yet!</div>
                                            <ProductVariants/>
                                        </div>
                                    )}
                                </div>
                            )}

                            {selectedTab === 2 && (
                                <div className="accordion">
                                    <CustomAccordion
                                        question="When will JaduPc pre-orders open?"
                                        answer="We will start accepting pre-orders for JaduPc starting 22 August 2023."
                                        panelNumber={1}
                                    />
                                    <CustomAccordion
                                        question="How much does it cost?"
                                        answer="You can get a standalone JaduPc CPU starting at TK5000 for the Starter model, or the Pro model for TK10,000. There is also the option to get a desktop package starting from TK6,000, on top of the CPU cost."
                                        panelNumber={2}
                                    />
                                    <CustomAccordion
                                        question="What does the desktop package come with?"
                                        answer="The desktop package includes a 19 inch monitor, a keyboard, and a mouse."
                                        panelNumber={3}
                                    />
                                    <CustomAccordion
                                        question="Where can I pre-order JaduPc?"
                                        answer="JaduPc pre-orders will be available exclusively online through the JaduPc website."
                                        panelNumber={4}
                                    />
                                    <CustomAccordion
                                        question="What payment methods are accepted for pre-orders?"
                                        answer="Payments can be made online through card and bKash."
                                        panelNumber={5}
                                    />
                                    <CustomAccordion
                                        question="When can I expect my pre-ordered JaduPc to arrive?"
                                        answer="We will begin shipping out pre-orders starting mid September 2023. You'll get an email notification when your JaduPc is on its way."
                                        panelNumber={6}
                                    />
                                    <CustomAccordion
                                        question="What if there's a problem with my JaduPc?"
                                        answer="JaduPc comes with a 1-year warranty and comprehensive technical support."
                                        panelNumber={7}
                                    />
                                    <CustomAccordion
                                        question="What is ShopnoOS?"
                                        answer="JaduPc comes with a proprietary Linux based operating system called ShopnoOS, which is designed to replicate the smooth user experience of Windows, while being optimized for low cost hardware."
                                        panelNumber={8}
                                    />
                                    <CustomAccordion
                                        question="How do I set up my JaduPc?"
                                        answer="JaduPc comes with a suite of pre-installed software out of the box, including Avro, LibreOffice, Chrome, Firefox, Media Player, GIMP, Kolourpaint, and IDEs like Visual Studio Code, Spyder, Arduino IDE, Turbowarp/Scratch, and CONVAY for online meetings."
                                        panelNumber={9}
                                    />
                                    <CustomAccordion
                                        question="Is Microsoft Windows included with JaduPc?"
                                        answer="No, JaduPc does not come with Microsoft Windows."
                                        panelNumber={10}
                                    />
                                    <CustomAccordion
                                        question="Can I edit photos or videos on a JaduPc Starter?"
                                        answer="No, JaduPc Starter does not support memory intensive tasks such as photo or video editing."
                                        panelNumber={11}
                                    />
                                </div>
                            )}

                            {selectedTab === 3 && (
                                <div className="coming-soon">Coming soon!</div>
                            )}

                            {selectedTab === 4 && (
                                <div className="coming-soon">Coming soon!</div>
                            )}

                            {selectedTab === 5 && (
                                <div className="coming-soon">Coming soon!</div>
                            )}
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
}

export default Dashboard;
